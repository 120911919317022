import Bugsnag from "@bugsnag/js";
import React, { Component } from "react";
import SEO from "react-seo-component";
import MainView from "../landing/section";

class Section extends Component {
  constructor() {
    super();

    let promocode = "";
    try {
      const queryParams = new URLSearchParams(window.location.search);
      promocode = queryParams.get("promocode") || "";
    } catch (error) {
      Bugsnag.notify(error);
    }

    this.state = {
      loading: false,
      success: false,
      trackYourOrder: false,
      openSuccessModal: false,
      trucktypes: "motorbike",
      apierror: "",
      collectionlat: 0,
      collectionlng: 0,
      fullname: "",
      frequency: "monthly",
      email: "",
      mobilenumber: "",
      companyname: "",
      collectiondate: "",
      collectiontime: "",
      description: "",
      driverrequired: 1,
      openDiscountCode: false,
      promocode: promocode || "",
      vehicletypes: [],
    };
  }

  render() {
    return (
      <MainView
        heading={<h3 className="main-headline">{this.props.title}</h3>}
        description={this.props.pageDescription || "Book a demo today"}
        seo={
          <SEO
            title={this.props.title || "Book a demo today"}
            p
            titleTemplate={this.props.titleTemplate || "QikTruck"}
            titleSeparator={this.props.titleSeparator || `-`}
            description={
              this.props.pageDescription ||
              "Hire a delivery vehicle for your business and save on your transportation costs."
            }
            image={
              this.props.image ||
              "https://media.qiktruck.co.za/qiktruck-preview-header.png"
            }
            pathname={
              this.props.pathname || "https://qiktruck.co.za/regular-deliveries"
            }
            twitterUsername={"qiktrucksa"}
          />
        }
      />
    );
  }
}

export default Section;
