import { MessageOutlined } from "@ant-design/icons";
import { Button, Drawer, Input, List, message } from "antd";
import axios from "axios";
import moment from "moment/moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import cookie from "react-cookies";
import constants from "../../Constants";

const baseURL = constants.baseURL;

const ChatApp = () => {
  const [visible, setVisible] = useState(false);
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [sending, setSending] = useState(false);
  const chatDrawerRef = useRef(null);

  const urlParams = new URLSearchParams(window.location.search);
  const quoteid = urlParams.get("quoteid") || localStorage.getItem("quoteid");
  const orderid = urlParams.get("orderid") || localStorage.getItem("orderid");
  const isdriver = urlParams.get("isdriver");

  const scrollToBottom = () => {
    const chatMessages = document.getElementById("chat-messages");
    if (chatMessages) {
      chatMessages.scrollTop = chatMessages?.scrollHeight;
    }
  };

  // Track tab visibility
  useEffect(() => {
    try {
      if (cookie.load("quotetoken") && !isdriver) {
        const enableAudio = () => {
          const notification1 = new Audio("/notification.mp3");
          notification1.play().catch((e) => {
            console.log("Audio error xx", e);
          }); // Ignore errors
          document.removeEventListener("click", enableAudio); // Remove listener after first interaction
        };
        document.addEventListener("click", enableAudio, { once: true });
      }
    } catch (error) {
      console.error("Audio error", error);
    }
  }, [isdriver]);
  const fetchMessages = useCallback(async () => {
    try {
      if ((!quoteid && !orderid) || (!cookie.load("quotetoken") || isdriver))
        return;
      setLoading(true);
      const response = await axios.get(
        baseURL +
          (quoteid
            ? "/customer-quote-comments?quoteid="
            : "/customer-order-comments?orderid=") +
          (quoteid || orderid),
        {
          headers: {
            "Content-Type": "application/json",
            authorization: cookie.load("quotetoken"),
          },
        }
      );

      setMessages(response.data);
      if (
        response.data.length > 0 &&
        response.data.length > localStorage.getItem("local-notifications")
      ) {
        const notification1 = new Audio("/notification1.mp3");
        notification1.play().catch((e) => {
          console.log("Audio error 1", e);
        }); // Ignore errors
        const notification2 = new Audio("/notification1.mp3");
        notification2.play().catch((e) => {
          console.log("Audio error 2", e);
        }); // Ignore errors
        setTimeout(() => {
          notification1.play().catch((e) => {
            console.log("Audio error 1", e);
          }); //
        }, 5000);
        localStorage.setItem("local-notifications", response.data.length);
        scrollToBottom();
      }
    } catch (error) {
      console.error(error);
      message.error("Failed to load messages");
    } finally {
      setLoading(false);
    }
  }, [quoteid, orderid, isdriver]);

  // Fetch messages every 15 seconds only if tab is active and chat is open
  useEffect(() => {
    let interval;
    fetchMessages();
    interval = setInterval(fetchMessages, 15000);
    return () => {
      if (interval) clearInterval(interval);
    };
  }, [fetchMessages]);

  const handleSendMessage = async () => {
    if (!inputValue.trim()) return;
    setSending(true);
    scrollToBottom();
    try {
      await axios.post(
        baseURL +
          (quoteid
            ? "/customer-quote-comments"
            : "/customer-order-comments/send-message"),
        {
          message: inputValue,
          quoteid,
          orderid,
        },
        {
          headers: {
            "Content-Type": "application/json",
            authorization: cookie.load("quotetoken"),
          },
        }
      );
      setInputValue("");
      fetchMessages();
    } catch (error) {
      console.error(error);
      message.error("Failed to send message");
    } finally {
      setSending(false);
    }
  };

  return (
    <>
      {!visible &&
        cookie.load("quotetoken") &&
        !isdriver &&
        moment().isBetween(
          moment().hour(8).minute(30),
          moment().hour(24),
          "hour",
          "[]"
        ) && (
          <>
            <div
              style={{
                position: "fixed",
                bottom: 70,
                right: 24,
                zIndex: 1000000,
                background: "#4f6367",
                boxShadow: "0 2px 80px rgba(0, 0, 0, 1)",
                color: "white",
                padding: "4px 8px",
                borderRadius: 4,
                cursor: "pointer",
                textAlign: "center",
                fontSize: 12,
              }}
            >
              Quick question?
            </div>
            <Button
              type="primary"
              shape="circle"
              icon={<MessageOutlined />}
              size="large"
              style={{
                position: "fixed",
                bottom: 24,
                right: 24,
                zIndex: 1000000,
                background: "orange",
                boxShadow: "0 2px 80px rgba(0, 0, 0, 1)",
              }}
              onClick={() => setVisible(true)}
            />
          </>
        )}

      <Drawer
        title={
          <div style={{ flexDirection: "row", display: "flex" }}>
            <MessageOutlined />
            <div style={{ marginLeft: 8 }}>Chat with Admin</div>
            <a
              href={
                localStorage.getItem("quoteid")
                  ? "/quote?quoteid=" + localStorage.getItem("quoteid")
                  : localStorage.getItem("orderid")
                  ? "/track-order?paynow=yes&orderid=" +
                    localStorage.getItem("orderid")
                  : ""
              }
              style={{ marginLeft: "auto" }}
            >
              {localStorage.getItem("quoteid")
                ? " View Quote"
                : localStorage.getItem("orderid")
                ? "View Order"
                : ""}
            </a>
          </div>
        }
        placement="right"
        closable={true}
        onClose={() => setVisible(false)}
        open={visible}
        width="100%"
        ref={chatDrawerRef} // Reference for visibility tracking
        styles={{
          body: {
            display: "flex",
            flexDirection: "column",
            padding: "0px 5px 0px 5px",
          },
        }}
      >
        <div
          style={{
            flex: 0.9,
            overflowY: "auto",
            padding: 16,
            background: "#4f6367",
            color: "white",
            borderRadius: "16px",
          }}
          id="chat-messages"
        >
          <List
            dataSource={messages}
            renderItem={(item) => {
              const isCurrentUser = item.actorid && item.actorid.includes("@");
              return (
                <List.Item
                  key={item.orderconversationid}
                  className={`chat-message ${isCurrentUser ? "user" : "other"}`}
                >
                  <div className="bubble-owner">
                    {item.adminfirstname ||
                      item.driverfirstname ||
                      item.customerfirstname ||
                      "you"}
                    <div className="bubble-time">
                      {moment(item.dateadded).format("DD MMM") +
                        " " +
                        moment(item.dateadded).format("hh:mm A")}
                    </div>
                  </div>
                  <div className="bubble">{item.message}</div>
                </List.Item>
              );
            }}
            locale={{ emptyText: <p>No messages yet</p> }}
          />
          {loading && (
            <p
              style={{
                textAlign: "center",
                color: "grey",
                padding: 16,
                fontSize: 12,
              }}
            >
              contacting admin...
            </p>
          )}
        </div>

        <div style={{ padding: 16, borderTop: "1px solid #f0f0f0" }}>
          <Input.Search
            placeholder="Type your message..."
            enterButton="Send"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onSearch={handleSendMessage}
            disabled={sending || !cookie.load("quotetoken")}
          />
        </div>
      </Drawer>
    </>
  );
};

export default ChatApp;
