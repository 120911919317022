import React from "react";
import { Container, Row, Col } from "reactstrap";
import { Helmet } from "react-helmet";
import Footer from "../../components/Footer/footer";

export default function VehicleInsurance() {
  return (
    <React.Fragment>
      <Helmet>
        <title>Vehicle Insurance Guide | QikTruck Driver Support</title>
        <meta
          name="description"
          content="Understand your vehicle insurance coverage as a QikTruck driver, including claims process and required documentation."
        />
      </Helmet>

      <section className="section bg-light pt-5">
        <Container>
          <Row className="justify-content-center">
            <Col lg={8}>
              <div className="section-title text-center mb-5">
                <h1 className="font-weight-bold">Vehicle Insurance Guide</h1>
                <p className="text-muted">
                  Understanding your coverage, claims process, and requirements
                </p>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg={8} className="mx-auto">
              <div className="card shadow-sm rounded p-4 mb-4">
                <div className="d-flex align-items-center mb-4">
                  <div className="bg-primary rounded p-3 mr-4">
                    <i className="fa fa-car text-white h3 mb-0"></i>
                  </div>
                  <div>
                    <h3 className="mb-1">Insurance Coverage Types</h3>
                    <p className="text-muted mb-0">Understanding what protection you have</p>
                  </div>
                </div>
                <div className="mb-4">
                  <p>
                    As a QikTruck driver, having the right insurance coverage is essential. Here's a 
                    breakdown of the different types of coverage that protect you and your vehicle:
                  </p>
                </div>
                <div className="table-responsive">
                  <table className="table table-bordered">
                    <thead className="bg-light">
                      <tr>
                        <th>Coverage Type</th>
                        <th>What It Covers</th>
                        <th>Who Provides It</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Comprehensive</td>
                        <td>Damage to your vehicle not caused by a collision (theft, fire, natural disasters)</td>
                        <td>Your personal insurance + QikTruck supplemental</td>
                      </tr>
                      <tr>
                        <td>Collision</td>
                        <td>Damage to your vehicle from accidents regardless of fault</td>
                        <td>Your personal insurance + QikTruck supplemental</td>
                      </tr>
                      <tr>
                        <td>Liability</td>
                        <td>Damage you cause to others' property or injuries to others</td>
                        <td>Your commercial insurance + QikTruck liability</td>
                      </tr>
                      <tr>
                        <td>Contingent Coverage</td>
                        <td>Protection when your app is on but you haven't accepted a delivery</td>
                        <td>QikTruck insurance program</td>
                      </tr>
                      <tr>
                        <td>Delivery Period Coverage</td>
                        <td>Enhanced protection when actively delivering for QikTruck</td>
                        <td>QikTruck insurance program</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="alert alert-info mt-3">
                  <strong>Important:</strong> Always inform your personal insurance provider that you're 
                  using your vehicle for deliveries. Many personal policies don't cover commercial use, 
                  which could lead to denied claims.
                </div>
              </div>

              <div className="card shadow-sm rounded p-4 mb-4">
                <div className="d-flex align-items-center mb-4">
                  <div className="bg-success rounded p-3 mr-4">
                    <i className="fa fa-file-text-o text-white h3 mb-0"></i>
                  </div>
                  <div>
                    <h3 className="mb-1">Claims Process</h3>
                    <p className="text-muted mb-0">Step-by-step guide to filing an insurance claim</p>
                  </div>
                </div>
                <p>
                  If you're involved in an accident or your vehicle is damaged, follow these steps to 
                  file a claim:
                </p>
                <div className="bg-light p-3 rounded mb-4">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="d-flex mb-3">
                        <div className="badge badge-success mr-3 pt-2">1</div>
                        <div>
                          <h5 className="mb-1">Safety First</h5>
                          <p className="mb-0">Ensure everyone's safety and call emergency services if needed</p>
                        </div>
                      </div>
                      <div className="d-flex mb-3">
                        <div className="badge badge-success mr-3 pt-2">2</div>
                        <div>
                          <h5 className="mb-1">Document Everything</h5>
                          <p className="mb-0">Take photos of damage, exchange information with other parties</p>
                        </div>
                      </div>
                      <div className="d-flex mb-3">
                        <div className="badge badge-success mr-3 pt-2">3</div>
                        <div>
                          <h5 className="mb-1">Report in App</h5>
                          <p className="mb-0">Use the "Report Incident" feature in your QikTruck driver app</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="d-flex mb-3">
                        <div className="badge badge-success mr-3 pt-2">4</div>
                        <div>
                          <h5 className="mb-1">Contact QikTruck Support</h5>
                          <p className="mb-0">Call our dedicated insurance hotline at 0800-QIK-HELP</p>
                        </div>
                      </div>
                      <div className="d-flex mb-3">
                        <div className="badge badge-success mr-3 pt-2">5</div>
                        <div>
                          <h5 className="mb-1">Notify Your Insurer</h5>
                          <p className="mb-0">Report the incident to your personal insurance company</p>
                        </div>
                      </div>
                      <div className="d-flex mb-3">
                        <div className="badge badge-success mr-3 pt-2">6</div>
                        <div>
                          <h5 className="mb-1">Follow Up</h5>
                          <p className="mb-0">Respond promptly to requests for additional information</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="alert alert-warning">
                  <strong>Time Sensitive!</strong> Claims should be reported within 24 hours of an incident 
                  to ensure proper coverage and expedited processing.
                </div>
              </div>

              <div className="card shadow-sm rounded p-4 mb-4">
                <div className="d-flex align-items-center mb-4">
                  <div className="bg-warning rounded p-3 mr-4">
                    <i className="fa fa-clipboard text-white h3 mb-0"></i>
                  </div>
                  <div>
                    <h3 className="mb-1">Required Documentation</h3>
                    <p className="text-muted mb-0">Essential documents for insurance coverage</p>
                  </div>
                </div>
                <p>
                  To ensure you're properly covered and claims are processed efficiently, keep these 
                  documents updated and readily available:
                </p>
                <div className="row">
                  <div className="col-md-6 mb-3">
                    <div className="card h-100">
                      <div className="card-header bg-light">
                        <h5 className="mb-0">For Your Vehicle</h5>
                      </div>
                      <ul className="list-group list-group-flush">
                        <li className="list-group-item d-flex align-items-center">
                          <i className="fa fa-check-circle text-success mr-2"></i>
                          Proof of commercial auto insurance
                        </li>
                        <li className="list-group-item d-flex align-items-center">
                          <i className="fa fa-check-circle text-success mr-2"></i>
                          Vehicle registration documents
                        </li>
                        <li className="list-group-item d-flex align-items-center">
                          <i className="fa fa-check-circle text-success mr-2"></i>
                          Roadworthy certificate
                        </li>
                        <li className="list-group-item d-flex align-items-center">
                          <i className="fa fa-check-circle text-success mr-2"></i>
                          Maintenance records
                        </li>
                        <li className="list-group-item d-flex align-items-center">
                          <i className="fa fa-check-circle text-success mr-2"></i>
                          Purchase or lease agreement
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <div className="card h-100">
                      <div className="card-header bg-light">
                        <h5 className="mb-0">For You as a Driver</h5>
                      </div>
                      <ul className="list-group list-group-flush">
                        <li className="list-group-item d-flex align-items-center">
                          <i className="fa fa-check-circle text-success mr-2"></i>
                          Valid driver's license
                        </li>
                        <li className="list-group-item d-flex align-items-center">
                          <i className="fa fa-check-circle text-success mr-2"></i>
                          Professional driving permit (PrDP)
                        </li>
                        <li className="list-group-item d-flex align-items-center">
                          <i className="fa fa-check-circle text-success mr-2"></i>
                          QikTruck driver agreement
                        </li>
                        <li className="list-group-item d-flex align-items-center">
                          <i className="fa fa-check-circle text-success mr-2"></i>
                          Proof of address (recent utility bill)
                        </li>
                        <li className="list-group-item d-flex align-items-center">
                          <i className="fa fa-check-circle text-success mr-2"></i>
                          Medical certificate (if required)
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="bg-light p-3 rounded mt-3">
                  <h5>Digital Document Storage</h5>
                  <p>
                    Use the "Document Vault" feature in your QikTruck app to store digital copies of all 
                    important documents. This ensures you have access to them whenever needed, even if the 
                    physical copies are unavailable.
                  </p>
                </div>
              </div>

              <div className="card shadow-sm rounded p-4 mb-4">
                <div className="d-flex align-items-center mb-4">
                  <div className="bg-info rounded p-3 mr-4">
                    <i className="fa fa-question-circle text-white h3 mb-0"></i>
                  </div>
                  <div>
                    <h3 className="mb-1">Frequently Asked Questions</h3>
                    <p className="text-muted mb-0">Common insurance questions from drivers</p>
                  </div>
                </div>
                <div className="accordion" id="insuranceFAQ">
                  <div className="card mb-2">
                    <div className="card-header" id="headingOne">
                      <h5 className="mb-0">
                        <button className="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseOne">
                          Does my personal auto insurance cover deliveries?
                        </button>
                      </h5>
                    </div>
                    <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#insuranceFAQ">
                      <div className="card-body">
                        Most personal auto insurance policies specifically exclude coverage when your vehicle 
                        is used for commercial purposes like deliveries. This is why QikTruck provides 
                        supplemental insurance and why you should consider a commercial auto policy if you 
                        drive frequently.
                      </div>
                    </div>
                  </div>
                  <div className="card mb-2">
                    <div className="card-header" id="headingTwo">
                      <h5 className="mb-0">
                        <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo">
                          When does QikTruck's insurance coverage apply?
                        </button>
                      </h5>
                    </div>
                    <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#insuranceFAQ">
                      <div className="card-body">
                        QikTruck's insurance coverage applies when you're logged into the app and available for 
                        deliveries (contingent coverage), and provides enhanced coverage when you've accepted a 
                        delivery and are en route to pickup or delivering to a customer.
                      </div>
                    </div>
                  </div>
                  <div className="card mb-2">
                    <div className="card-header" id="headingThree">
                      <h5 className="mb-0">
                        <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseThree">
                          What's my deductible for QikTruck insurance claims?
                        </button>
                      </h5>
                    </div>
                    <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#insuranceFAQ">
                      <div className="card-body">
                        The standard deductible for QikTruck insurance claims is R2,500. However, drivers 
                        with an excellent safety record (no accidents in 12+ months) may qualify for reduced 
                        deductibles through our Safe Driver Program.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <Footer />
    </React.Fragment>
  );
} 