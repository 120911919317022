import React, { useState, useEffect, useMemo } from "react";

const LetterByLetter = () => {
  const phrases = useMemo(
    () => [
      "Quotation in 30 seconds",
      "Hassle-free truck booking",
      "Reliable on-time delivery",
      "Instant tracking and updates",
      "Wide network of verified drivers",
      "Affordable and transparent pricing",
      "Safe and secure cargo handling",
      "Flexible scheduling and cancellations",
      "Dedicated customer support",
      "Eco-friendly logistics solutions",
      "Efficient fleet management",
    ],
    []
  );

  const [currentPhraseIndex, setCurrentPhraseIndex] = useState(0);
  const [currentText, setCurrentText] = useState("");
  const [charIndex, setCharIndex] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      setCurrentText((prevText) =>
        prevText
          ? prevText + phrases[currentPhraseIndex][charIndex]
          : phrases[currentPhraseIndex][charIndex]
      );
      setCharIndex((prevCharIndex) => prevCharIndex + 1);
    }, 60); // Change this value to control the typing speed

    if (charIndex === phrases[currentPhraseIndex].length) {
      clearTimeout(timer);
      setTimeout(() => {
        setCurrentPhraseIndex((prevIndex) => (prevIndex + 1) % phrases.length);
        setCurrentText("");
        setCharIndex(0);
      }, 3000); // 3-second pause before the next phrase
    }

    return () => clearTimeout(timer);
  }, [charIndex, currentPhraseIndex, phrases]);

  return (
    <a href="/#quotestart" className="nav-delivery-quotation highlight">
      {currentText}
    </a>
  );
};

export default LetterByLetter;
