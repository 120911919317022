import React from "react";
import appleDownloadImage from "../../assets/images/apple-play-download.svg";
import androidDownloadImage from "../../assets/images/google-play-download.png";

const AppDownloadLinks = () => {
  return (
    <div className="app-download-links">
      <a
        href="https://apps.apple.com/za/app/qiktruck-bakkie-truck-hire/id6741314137"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={appleDownloadImage}
          alt="App Download"
          width="150px"
          style={{ width: "100%" }}
        />
      </a>
      <a
        href="https://play.google.com/store/apps/details?id=za.co.qiktruck"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={androidDownloadImage}
          alt="App Download"
          width="150px"
          style={{ width: "100%" }}
        />
      </a>
    </div>
  );
};

export default AppDownloadLinks;
