import React from "react";
import { Container, Row, Col } from "reactstrap";
import { Helmet } from "react-helmet";
import Footer from "../../components/Footer/footer";

export default function DeliveryTips() {
  return (
    <React.Fragment>
      <Helmet>
        <title>Delivery Tips & Tricks | QikTruck Driver Guide</title>
        <meta
          name="description"
          content="Learn how to efficiently handle deliveries, plan routes, and improve customer interactions as a QikTruck driver."
        />
      </Helmet>

      <section className="section bg-light pt-5">
        <Container>
          <Row className="justify-content-center">
            <Col lg={8}>
              <div className="section-title text-center mb-5">
                <h1 className="font-weight-bold">Delivery Tips & Tricks</h1>
                <p className="text-muted">
                  Proven strategies to make your deliveries more efficient and customer-friendly
                </p>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg={8} className="mx-auto">
              <div className="card shadow-sm rounded p-4 mb-4">
                <div className="d-flex align-items-center mb-4">
                  <div className="bg-primary rounded p-3 mr-4">
                    <i className="fa fa-map-o text-white h3 mb-0"></i>
                  </div>
                  <div>
                    <h3 className="mb-1">Route Planning Excellence</h3>
                    <p className="text-muted mb-0">Optimize your delivery routes for efficiency</p>
                  </div>
                </div>
                <div className="mb-4">
                  <p>
                    Smart route planning is one of the most effective ways to improve your delivery 
                    efficiency and earn more. Here's how to plan your routes like a pro:
                  </p>
                </div>
                <div className="row">
                  <div className="col-md-6 mb-3">
                    <div className="card h-100">
                      <div className="card-body">
                        <h5 className="card-title">
                          <i className="fa fa-clock-o text-primary mr-2"></i>
                          Time-Based Planning
                        </h5>
                        <p className="card-text">
                          Consider delivery time windows, traffic patterns at different times of day, 
                          and business hours of delivery locations.
                        </p>
                        <div className="bg-light p-2 rounded">
                          <small><strong>Pro Tip:</strong> Use the QikTruck app's 
                          traffic prediction feature to avoid congestion.</small>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <div className="card h-100">
                      <div className="card-body">
                        <h5 className="card-title">
                          <i className="fa fa-road text-primary mr-2"></i>
                          Geographic Clustering
                        </h5>
                        <p className="card-text">
                          Group deliveries by area to minimize driving between locations. Accept orders 
                          that follow a logical route rather than scattered across town.
                        </p>
                        <div className="bg-light p-2 rounded">
                          <small><strong>Pro Tip:</strong> Use the "Delivery Heat Map" in the app 
                          to find high-density delivery areas.</small>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <div className="card h-100">
                      <div className="card-body">
                        <h5 className="card-title">
                          <i className="fa fa-building-o text-primary mr-2"></i>
                          Know Your Locations
                        </h5>
                        <p className="card-text">
                          Memorize building access codes, delivery entrances, and parking locations for 
                          frequent destinations to save time on each delivery.
                        </p>
                        <div className="bg-light p-2 rounded">
                          <small><strong>Pro Tip:</strong> Add delivery notes in the app to remember 
                          specific location details.</small>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <div className="card h-100">
                      <div className="card-body">
                        <h5 className="card-title">
                          <i className="fa fa-refresh text-primary mr-2"></i>
                          Flexible Adaptation
                        </h5>
                        <p className="card-text">
                          Be prepared to adjust your route for unexpected traffic, road closures, or 
                          new priority deliveries that come in during your shift.
                        </p>
                        <div className="bg-light p-2 rounded">
                          <small><strong>Pro Tip:</strong> Check traffic updates regularly and use the 
                          "Reroute" feature when needed.</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card shadow-sm rounded p-4 mb-4">
                <div className="d-flex align-items-center mb-4">
                  <div className="bg-success rounded p-3 mr-4">
                    <i className="fa fa-users text-white h3 mb-0"></i>
                  </div>
                  <div>
                    <h3 className="mb-1">Customer Service Excellence</h3>
                    <p className="text-muted mb-0">Building positive relationships with every delivery</p>
                  </div>
                </div>
                <p>
                  Great customer service leads to higher ratings, better tips, and more delivery 
                  opportunities. Here's how to impress your customers:
                </p>
                <div className="table-responsive mb-3">
                  <table className="table table-bordered">
                    <thead className="bg-light">
                      <tr>
                        <th>Interaction Point</th>
                        <th>Best Practice</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Arrival Communication</td>
                        <td>Send an app notification when you're 5 minutes away so customers can prepare</td>
                      </tr>
                      <tr>
                        <td>First Impression</td>
                        <td>Smile, make eye contact, and greet the customer by name</td>
                      </tr>
                      <tr>
                        <td>Item Handling</td>
                        <td>Handle packages with care and respect, especially when customers are watching</td>
                      </tr>
                      <tr>
                        <td>Placement</td>
                        <td>Ask where they'd like items placed rather than assuming</td>
                      </tr>
                      <tr>
                        <td>Extra Mile</td>
                        <td>Offer to remove packaging or place items in a specific location when appropriate</td>
                      </tr>
                      <tr>
                        <td>Departure</td>
                        <td>Thank the customer and wish them a good day</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="alert alert-success">
                  <strong>Did You Know?</strong> Drivers who maintain a 4.8+ star rating receive priority 
                  for premium deliveries that typically pay more.
                </div>
              </div>

              <div className="card shadow-sm rounded p-4 mb-4">
                <div className="d-flex align-items-center mb-4">
                  <div className="bg-warning rounded p-3 mr-4">
                    <i className="fa fa-lightbulb-o text-white h3 mb-0"></i>
                  </div>
                  <div>
                    <h3 className="mb-1">Efficiency Hacks</h3>
                    <p className="text-muted mb-0">Time-saving tips from experienced drivers</p>
                  </div>
                </div>
                <p>
                  These practical hacks can save you valuable minutes on each delivery, 
                  allowing you to complete more orders and increase your earnings:
                </p>
                <div className="row">
                  <div className="col-md-6">
                    <div className="bg-light p-3 rounded mb-3">
                      <h5>Before Your Shift</h5>
                      <ol>
                        <li>
                          <strong>Prepare your vehicle</strong> - Clean out unnecessary items to make space 
                          for deliveries
                        </li>
                        <li>
                          <strong>Pack a driver's kit</strong> - Include pens, notepad, phone charger, water, 
                          snacks, and weather protection
                        </li>
                        <li>
                          <strong>Check app updates</strong> - Ensure your app is updated and 
                          working properly
                        </li>
                        <li>
                          <strong>Review frequent stops</strong> - Refresh your memory on building 
                          access details
                        </li>
                      </ol>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="bg-light p-3 rounded mb-3">
                      <h5>During Deliveries</h5>
                      <ol>
                        <li>
                          <strong>Use a delivery caddy</strong> - Keep delivery essentials organized and accessible
                        </li>
                        <li>
                          <strong>Photo organization</strong> - Develop a system for quickly taking 
                          clear delivery confirmation photos
                        </li>
                        <li>
                          <strong>Parking strategies</strong> - Scout quick-access parking spots near 
                          frequent delivery locations
                        </li>
                        <li>
                          <strong>Batch notifications</strong> - Notify multiple customers at once when 
                          delivering to the same building
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
                <div className="bg-light p-3 rounded mt-2">
                  <h5>Multi-Order Delivery Strategy</h5>
                  <p>
                    When handling multiple orders at once:
                  </p>
                  <ul>
                    <li>Organize packages in your vehicle according to delivery order</li>
                    <li>Use colored labels or markers to quickly identify which package goes where</li>
                    <li>Keep high-priority or express deliveries in a separate, easily accessible area</li>
                    <li>Double-check addresses before leaving each location to avoid backtracking</li>
                  </ul>
                </div>
              </div>

              <div className="card shadow-sm rounded p-4 mb-4">
                <div className="d-flex align-items-center mb-4">
                  <div className="bg-info rounded p-3 mr-4">
                    <i className="fa fa-bar-chart text-white h3 mb-0"></i>
                  </div>
                  <div>
                    <h3 className="mb-1">Maximizing Your Earnings</h3>
                    <p className="text-muted mb-0">Strategic approaches to increase your income</p>
                  </div>
                </div>
                <div className="mb-4">
                  <p>
                    Smart strategic choices can significantly increase your earnings as a QikTruck driver:
                  </p>
                </div>
                <div className="row">
                  <div className="col-md-4 mb-3">
                    <div className="card h-100 text-center">
                      <div className="card-body">
                        <h5 className="card-title">Peak Hours</h5>
                        <i className="fa fa-line-chart fa-3x text-success my-3"></i>
                        <p className="card-text">
                          Work during high-demand times when surge pricing is in effect: weekday 
                          mornings, lunch hours, and evenings.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 mb-3">
                    <div className="card h-100 text-center">
                      <div className="card-body">
                        <h5 className="card-title">Special Handling</h5>
                        <i className="fa fa-cube fa-3x text-primary my-3"></i>
                        <p className="card-text">
                          Qualify for special delivery types (fragile items, large furniture) 
                          which pay premium rates.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 mb-3">
                    <div className="card h-100 text-center">
                      <div className="card-body">
                        <h5 className="card-title">Quest Bonuses</h5>
                        <i className="fa fa-trophy fa-3x text-warning my-3"></i>
                        <p className="card-text">
                          Complete delivery quests (e.g., "10 deliveries in 4 hours") to earn significant bonuses.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="alert alert-info mt-3">
                  <h5 className="alert-heading">Driver Community</h5>
                  <p>
                    Join the QikTruck Driver Community in the app to share tips, ask questions, and learn 
                    from experienced drivers about maximizing your earnings in your specific area.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <Footer />
    </React.Fragment>
  );
} 