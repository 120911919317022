import Bugsnag from "@bugsnag/js";
import axios from "axios";
import React, { Component } from "react";
import cookie from "react-cookies";
import CurrencyFormat from "react-currency-format";
import Marquee from "react-fast-marquee";

import {
  Button,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import constants from "../../Constants";
import PlacesAutocomplete from "../../components/common/PlacesAutocomplete";
import PriceMiniView from "../../components/common/PriceMiniView";
import PriceView from "../../components/common/PriceView";
import util from "../../util";
import reviews from "../articles/reviews";

const baseURL = constants.baseURL;
class Section extends Component {
  constructor() {
    super();

    let promocode = "";
    let vehicleId = "";
    let truckType = "";
    let justbook = "";
    let deliverylat = "";
    let collectiondate = "";
    let collectiontime = "";
    let description = "";
    let deliverylng = "";
    let collectionlat = "";
    let collectionlng = "";
    let category = "handheld-items";
    let runners = 1;
    let floors = 0;
    let prospectemail = "";
    let prospectmobilenumber = "";
    let quoteid = "";
    try {
      const queryParams = new URLSearchParams(window.location.search);
      promocode = queryParams.get("promocode") || "";
      truckType = queryParams.get("trucktype") || "1.5tonopen";
      justbook = queryParams.get("justbook") || "";
      description = queryParams.get("description") || "";
      deliverylat = queryParams.get("deliverylat") || "";
      deliverylng = queryParams.get("deliverylng") || "";
      collectiondate = queryParams.get("collectiondate") || "";
      collectiontime = queryParams.get("collectiontime") || "";
      collectionlat = queryParams.get("collectionlat") || "";
      collectionlng = queryParams.get("collectionlng") || "";
      category = queryParams.get("category") || "handheld-items";
      runners = queryParams.get("runners") || 1;
      floors = queryParams.get("floors") || 0;
      prospectemail = queryParams.get("prospectemail") || "";
      prospectmobilenumber = queryParams.get("prospectmobilenumber") || "";
      vehicleId = queryParams.get("v") || "";
      quoteid = queryParams.get("quoteid") || "";
    } catch (error) {
      Bugsnag.notify(error);
    }

    this.state = {
      quoteid,
      justbook,
      loading: false,
      success: false,
      trackYourOrder: false,
      openSuccessModal: false,
      truckType,
      acceptedOffer: "",
      apierror: "",
      orderid: "",
      collection: {
        lat: collectionlat,
        lng: collectionlng,
      },
      delivery: {
        lat: deliverylat,
        lng: deliverylng,
      },
      fullQuote: {
        tripDistance: 0,
        tripDuration: 0,
        sellingPrice: 0,
        storedQuoteId: "",
      },
      firstname: "",
      lastname: "",
      email: prospectemail,
      mobilenumber: prospectmobilenumber,
      collectiondate,
      collectiontime,
      description,
      duration: 0,
      trips: 0,
      prospectemail,
      prospectmobilenumber,
      vehicletypes: [],
      vehiclecategories: [],
      showOtherCategories: false,
      usercategory: category,
      vehicleconfigurations: {},
      openDiscountCode: false,
      promocode: promocode || "",
      vehicleId: vehicleId || "",
      runners,
      floors,
      quoteProfile: {},
      stories: [],
      expanded: [],
    };
  }

  componentDidMount() {
    this.getVehicleTypes();
    this.getQuoteProfile();
    if (this.validStepOne()) this.getQuote();
  }
  driverCancelOrder() {
    this.setState({
      loading: true,
      apierror: "",
    });
    alert("Job cancelled");
    const { quoteid, quotevehicleid } = this.state;
    axios
      .post(baseURL + "/driver-cancel-quote", {
        quoteid,
        quotevehicleid,
      })
      .then((response) => {
        window.location.href = "/quote?quoteid=" + quoteid;
      })
      .catch((e) => {
        console.log("/driver-cancel-order  err", e);
        alert(e);
      })
      .finally(() => {});
  }
  getQuoteProfile() {
    //setloading
    this.setState({
      loading: true,
    });

    const { quoteid, truckType } = this.state;

    if (quoteid?.length > 3)
      axios
        .post(baseURL + "/quote-profile", {
          quoteid: quoteid,
          source_:
            util.navigator_() + util.source_() + " qtref:" + document?.referrer,
        })
        .then((repos) => {
          const quoteProfile = repos.data;
          this.setState({
            loading: false,
            ...quoteProfile,
            mobilenumber: quoteProfile.prospectmobilenumber,
            prospectmobilenumber: quoteProfile.prospectmobilenumber,
            delivery: {
              lat: quoteProfile.deliverylat,
              lng: quoteProfile.deliverylng,
            },
            collection: {
              lat: quoteProfile.collectionlat,
              lng: quoteProfile.collectionlng,
            },
            firstname: quoteProfile.firstname || " ",
            lastname: quoteProfile.lastname || " ",
            email: quoteProfile.prospectemail || " ",
            prospectemail: quoteProfile.prospectemail || " ",
            budget: quoteProfile.budget / 100 || undefined,
            truckType: quoteProfile.trucktype || truckType,
            fullQuote: {
              tripDistance: quoteProfile.tripdistance,
              tripDuration: quoteProfile.duration,
              sellingPrice: quoteProfile.sellingprice,
              storedQuoteId: quoteProfile.quoteid,
              discount: quoteProfile.discount,
            },
          });
        });
  }

  getVehicleTypes() {
    const { vehicletypes } = this.state;
    if (vehicletypes?.length === 0)
      axios.get(baseURL + "/vehicle-types").then((repos) => {
        const vehicletypesdata = repos.data;
        this.setState({
          vehicletypes: vehicletypesdata?.vehicles,
          vehiclecategories: vehicletypesdata?.categories,
          vehicleconfigurations: vehicletypesdata?.configurations,
        });
      });
  }

  changeTruck(truckType) {
    this.setState(
      {
        truckType: truckType || "",
      },
      () => {
        if (window.document.getElementById("addresses"))
          window.document.getElementById("addresses").scrollIntoView();
      }
    );
  }
  validStepOne() {
    const { truckType, delivery, collection, prospectmobilenumber } =
      this.state;
    if (prospectmobilenumber.length === 0) return false;
    if (truckType === "") return false;
    if (delivery.lat === 0) return false;
    if (collection.lat === 0) return false;
    return !(
      collection.lat === delivery.lat && collection.lng === delivery.lng
    );
  }
  validStepTwo() {
    const {
      firstname,
      lastname,
      email,
      mobilenumber,
      collectiondate,
      collectiontime,
      description,
    } = this.state;

    return !(
      firstname.length === 0 ||
      lastname.length === 0 ||
      email.length === 0 ||
      mobilenumber.length === 0 ||
      collectiondate.length === 0 ||
      collectiontime.length === 0 ||
      description.length === 0
    );
  }
  getQuote() {
    const {
      truckType,
      delivery,
      collection,
      usercategory,
      promocode,
      runners,
      collectiondate, //required
      collectiontime, //required
      floors,
      description,
      duration,
      trips,
      prospectemail,
      prospectmobilenumber,
      budget,
    } = this.state;
    if (!this.validStepOne()) return;

    this.setState({
      loading: true,
      apierror: "",
      acceptedOffer: "",
      fullQuote: {
        tripDistance: 0,
        tripDuration: 0,
        sellingPrice: 0,
        storedQuoteId: "",
        discount: "",
      },
    });
    axios
      .post(baseURL + "/quote", {
        description,
        duration,
        trips,
        truckType: this.state.quoteProfile.trucktype || truckType,
        delivery,
        collection,
        category: usercategory,
        promocode: promocode,
        runners,
        floors,
        prospectemail,
        collectiondate, //required?
        collectiontime, //required?
        prospectmobilenumber,
        budget: budget * 100 || null,
        source_:
          util.navigator_() + util.source_() + " qtref:" + document?.referrer,
        quotevehicleid: this.state.quotevehicleid,
      })
      .then((response) => {
        if (response.data.sellingPrice > 0) {
          this.setState(
            {
              loading: false,
              fullQuote: {
                tripDistance: response.data.tripDistance,
                tripDuration: response.data.tripDuration,
                sellingPrice: response.data.sellingPrice,
                storedQuoteId: response.data.quoteId,
                discount: response.data.discount,
                otherOptions: response.data.otherOptions,
              },
            },
            () => {
              if (window.document.getElementById("mainquote"))
                window.document.getElementById("mainquote").scrollIntoView();
            }
          );
        }
      })
      .catch((e) => {
        this.setState({
          loading: false,
          apierror: "Check your addresses or vehicle type and try again.",
        });
        Bugsnag.notify(e);
      });
  }
  acceptQuote() {
    this.setState({
      loading: true,
      apierror: "",
    });
    const {
      fullQuote,
      firstname,
      lastname,
      prospectemail,
      mobilenumber,
      collectiondate,
      collectiontime,
      description,
      acceptedOffer,
    } = this.state;

    axios
      .post(baseURL + "/accept-quote", {
        fullQuote,
        firstname,
        lastname,
        email: prospectemail,
        mobilenumber,
        collectiondate,
        collectiontime,
        description,
        acceptedOffer: acceptedOffer?.toLowerCase(),
      })
      .then((response) => {
        this.setState(
          {
            loading: false,
            success: response.status === 200,
            openSuccessModal: true,
            orderid: response?.data?.orderid,
          },
          () => {
            if (response.status === 200 && response?.data?.orderid?.length > 0)
              cookie.save(
                "currentOrder",
                `email=${this.state.email}&orderid=${response.data.orderid}`,
                {
                  path: "/",
                  maxAge: 86400,
                  secure: true,
                }
              );
          }
        );
      })
      .catch((e) => {
        this.setState({
          loading: false,
          apierror: "Request failed. Please try again.",
        });
      });
  }
  vehiclePriceTip(vehicle, change) {
    function roundToNearestFifty(num) {
      return Math.round(num / 50) * 50;
    }
    let costOfDelivery =
      2000 * vehicle?.pricePerMeterCents + //cents
      1200 * vehicle?.pricePerSecondCents + //cents for 10 minutes
      vehicle?.handlingFeeCents; //cents
    let serviceFeePercentage = vehicle?.serviceFeePercentage * costOfDelivery;
    return roundToNearestFifty(
      ///hack for days /////!!!!!! needs review
      Math.round(
        ((costOfDelivery + serviceFeePercentage) * 1.15 * change || 1) / 100
      )
    );
  }
  getImage(trucktype) {
    try {
      return `https://media.qiktruck.co.za/${trucktype}.png`;
    } catch (error) {
      return "";
    }
  }
  getProfileImage(photo) {
    try {
      return `https://advertsmedia.qiktruck.co.za/${photo}`;
    } catch (error) {
      return "";
    }
  }
  expandVehicleView(str) {
    let arr = [...this.state.expanded];
    let index = arr.indexOf(str);
    if (index > -1) {
      arr.splice(index, 1);
    } else {
      arr.push(str);
    }
    this.setState({ expanded: arr });
  }
  getHappyDriver(num) {
    return (
      <img
        src={"https://media.qiktruck.co.za/happydriver" + (num + 1) + ".png"}
        alt=""
        className={"happydriver"}
      />
    );
  }
  render() {
    const {
      vehicletypes,
      promocode,
      runners,
      floors,
      fullQuote: { discount, otherOptions, sellingPrice },
      loading,
      truckType,
      collectionaddress,
      deliveryaddress,
    } = this.state;
    return (
      <React.Fragment>
        <div id={"pagefragmenttop"}></div>
        {this.props.seo}
        <section className="hero-section deliver-with-price" id={"pagetop"}>
          <Modal isOpen={this.state.success && this.state.openSuccessModal}>
            <ModalHeader>
              <i className={"mdi mdi-check orange-circle  order-received"}></i>
              Order Received
            </ModalHeader>
            <ModalBody>
              <ListGroup className="black-text">
                <ListGroupItem>
                  <i
                    className={
                      "mdi mdi-check orange-circle order-received-list"
                    }
                  ></i>
                  A driver will be in touch shortly to confirm your order.
                </ListGroupItem>
                <ListGroupItem>
                  <i className={"mdi mdi-email order-received-list"}></i>
                  You will receive an email with your invoice shortly.
                </ListGroupItem>
                <ListGroupItem>
                  <i
                    className={"mdi mdi-cash-multiple order-received-list"}
                  ></i>
                  You can pay now or after the delivery.
                </ListGroupItem>
                <ListGroupItem>
                  <i
                    className={"mdi mdi-cash-multiple order-received-list"}
                  ></i>
                  Follow the link in your email to make a payment.
                </ListGroupItem>
                <ListGroupItem>
                  <i
                    className={"mdi mdi-account-alert order-received-list"}
                  ></i>
                  Produce your ID when the driver arrives.
                </ListGroupItem>
                <ListGroupItem>
                  <i
                    className={
                      "mdi mdi-check orange-circle order-received-list"
                    }
                  ></i>
                  Happy moving!
                </ListGroupItem>
              </ListGroup>
            </ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                onClick={() =>
                  this.setState(
                    {
                      openSuccessModal: false,
                      trackYourOrder: true,
                    },
                    () => {
                      window.location.href =
                        "/track-order?paynow=yes&orderid=" +
                        this.state.orderid +
                        "&email=" +
                        this.state.email;
                    }
                  )
                }
              >
                Okay
              </Button>
            </ModalFooter>
          </Modal>
          <Modal isOpen={this.state.openDiscountCode}>
            <ModalHeader>
              <i className={"mdi mdi-clipboard-text order-received"}></i>
              More options
            </ModalHeader>
            <ModalBody>
              <Label> Add Helpers </Label>
              <br />
              <span>
                How many people do you need to carry and load the goods? The
                driver may not be able to help.
              </span>
              <Input
                id="runners"
                name="runners"
                type="number"
                defaultValue={runners}
                min={0}
                onChange={(e) => {
                  this.setState({ runners: parseInt(e.target.value, 10) });
                }}
              />
              <br />
              <br />
              <Label>Add Floors </Label>
              <br />
              <span>
                How many floors are we required to carry the goods? Add more
                information in your description later.
              </span>
              <Input
                id="floors"
                name="floors"
                type="number"
                min={0}
                defaultValue={floors}
                onChange={(e) => {
                  this.setState({ floors: parseInt(e.target.value, 10) });
                }}
              />
              <br />
              <br />
              <Label>Discount code</Label>
              <br />
              <span>Lucky one! Our Discount codes are up to 30% off!</span>
              <Input
                id="promocode"
                name="promocode"
                type="promocode"
                defaultValue={promocode}
                placeholder="Type your discount code here |"
                onChange={(e) => {
                  this.setState({ promocode: e.target.value.toUpperCase() });
                }}
              />
            </ModalBody>
            <ModalFooter>
              <Button
                color="secondary"
                onClick={() => {
                  this.setState({
                    openDiscountCode: false,
                  });
                }}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                onClick={() => {
                  this.getQuote();
                  this.setState({
                    openDiscountCode: false,
                  });
                }}
              >
                Apply
              </Button>
            </ModalFooter>
          </Modal>
          <Container>
            <div className="store-front">
              <Row>
                {loading && (
                  <div className="spinner">
                    <Spinner />
                  </div>
                )}
                <p
                  style={{
                    fontSize: 12,
                    color: "#999",
                    padding: 5,
                    width: "100%",
                  }}
                >
                  <span style={{ color: "yellow" }}>*</span> Vehicle size
                  matters
                </p>

                <div className="mini-menu-vehicles">
                  {vehicletypes
                    ?.filter((v) => v.type.indexOf("closed") === -1)
                    ?.map((v, index) => {
                      const image = this.getImage(v.type);
                      return (
                        <div
                          key={index}
                          className={
                            "mini-menu-vehicle " +
                            (truckType === v.type ? " picked" : "")
                          }
                          onClick={() => this.changeTruck(v.type)}
                        >
                          <i>
                            {v.type
                              .replace("open", " ")
                              .replace("andtrailer", " ")}
                          </i>
                          <img src={image} alt={v.type} />
                        </div>
                      );
                    })}
                </div>

                {this.state.viewmore ? (
                  <div className="mini-menu-vehicles">
                    {vehicletypes
                      ?.filter((v) => v.type.indexOf("open") === -1)
                      ?.map((v, index) => {
                        const image = this.getImage(v.type);
                        return (
                          <div
                            key={index}
                            className={
                              "mini-menu-vehicle " +
                              (truckType === v.type ? " picked" : "")
                            }
                            onClick={() => this.changeTruck(v.type)}
                          >
                            <i>
                              {v.type
                                .replace("closed", " ")
                                .replace("andtrailer", " ")}
                            </i>
                            <img src={image} alt={v.type} />
                          </div>
                        );
                      })}
                    <p
                      style={{
                        fontSize: 12,
                        color: "#999",
                        padding: 5,
                      }}
                      onClick={() => this.setState({ viewmore: false })}
                    >
                      view less
                    </p>
                  </div>
                ) : (
                  <p
                    style={{
                      fontSize: 12,
                      color: "#999",
                      padding: 5,
                      width: "100%",
                    }}
                    onClick={() => this.setState({ viewmore: true })}
                  >
                    view more vehicles
                  </p>
                )}
                <Col lg={12}>
                  <div className="divvyup b-center"></div>
                </Col>
                <Col lg={12}>
                  <div className="hero-wrapper mb-4" id={"quotestart"}>
                    <p></p>
                    <Row>
                      <Col lg={6} sm={6}>
                        <FormGroup autoComplete="new-password">
                          <h3
                            className="font-18"
                            id={"addresses"}
                            onClick={() => this.changeTruck()}
                          >
                            Collection
                          </h3>
                          <PlacesAutocomplete
                            type="text"
                            className="form-control"
                            id="collection"
                            placeholder={collectionaddress}
                            required
                            autoComplete="new-password"
                            onPlaceSelected={(place) => {
                              if (!place?.geometry?.location?.lat()) {
                                Bugsnag.notify(
                                  new Error(
                                    "COLLECTION place not found: " +
                                      this.state.truckType
                                  )
                                );
                              }
                              this.setState({
                                collection: {
                                  lat: place?.geometry?.location?.lat(),
                                  lng: place?.geometry?.location?.lng(),
                                },
                              });
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg={6} sm={6}>
                        <FormGroup className="mb-4" autoComplete="new-password">
                          <h3 className="font-18">Delivery </h3>

                          <PlacesAutocomplete
                            type="text"
                            className="form-control"
                            id="delivery"
                            placeholder={deliveryaddress}
                            required
                            autoComplete="new-password"
                            onPlaceSelected={(place) => {
                              if (!place?.geometry?.location?.lat()) {
                                Bugsnag.notify(
                                  new Error(
                                    "DELIVERY place not found: " +
                                      this.state.truckType
                                  )
                                );
                              }
                              this.setState({
                                delivery: {
                                  lat: place?.geometry?.location?.lat(),
                                  lng: place?.geometry?.location?.lng(),
                                },
                              });
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6} sm={6}>
                        <FormGroup>
                          <Input
                            id="prospectmobilenumber"
                            name="prospectmobilenumber"
                            placeholder="Mobile number *"
                            defaultValue={this.state.prospectmobilenumber}
                            onChange={(e) => {
                              this.setState({
                                prospectmobilenumber: e.target.value,
                                mobilenumber: e.target.value,
                              });
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg={6} sm={6}>
                        <FormGroup>
                          <div
                            onClick={() =>
                              this.setState({ openDiscountCode: true })
                            }
                            className="moreoptions"
                          >
                            <i className="mdi mdi-plus-circle-outline"></i>
                            {floors} Floor{floors === 1 ? "" : "s"}. {runners}{" "}
                            Helper
                            {runners === 1 ? "" : "s"}.
                            {promocode?.length > 0
                              ? "- Discount code: " + promocode
                              : " Discounts"}
                            {" >"}
                            <span></span>
                          </div>
                        </FormGroup>
                      </Col>

                      <Col lg={6} sm={6}>
                        <FormGroup>
                          <Label for="collectiondate1">Collection date</Label>
                          <Input
                            id="collectiondate1"
                            name="collectiondate1"
                            placeholder="Collection date"
                            defaultValue={this.state.collectiondate}
                            type="date"
                            onChange={(e) => {
                              this.setState({ collectiondate: e.target.value });
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg={6} sm={6}>
                        <FormGroup>
                          <Label for="collectiontime1">Time</Label>
                          <Input
                            id="collectiontime1"
                            name="collectiontime1"
                            defaultValue={this.state.collectiontime}
                            placeholder="Time"
                            type="time"
                            onChange={(e) => {
                              this.setState({ collectiontime: e.target.value });
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg={12} sm={12}>
                        <FormGroup>
                          {this.state.budget ? (
                            <div
                              style={{
                                fontSize: 12,
                                color: "#999",
                                marginTop: 5,
                                padding: 5,
                              }}
                            >
                              <CurrencyFormat
                                value={this.state.budget}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"R"}
                                renderText={(value) => <div>{value}</div>}
                              />
                              is the offered price.
                            </div>
                          ) : (
                            <p
                              style={{
                                fontSize: 12,
                                color: "#999",
                                padding: 5,
                              }}
                            >
                              <span style={{ color: "brown" }}>*</span> What's
                              the budget
                            </p>
                          )}
                          <Input
                            id="budget"
                            name="budget"
                            type="number"
                            className="offer"
                            placeholder="Customer Offer in Rands *"
                            defaultValue={this.state.budget}
                            onChange={(e) => {
                              this.setState({
                                budget: e.target.value,
                              });
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg={12} sm={12}>
                        <FormGroup>
                          <Input
                            id="description"
                            name="text"
                            type="textarea"
                            defaultValue={this.state.description}
                            style={{ minHeight: 120 }}
                            placeholder="Details about what we are moving... *"
                            onChange={(e) => {
                              this.setState({ description: e.target.value });
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <div className="instant-quotations-parent">
                      <Button
                        className="instant-quotations"
                        color="primary"
                        onClick={() => {
                          this.getQuote();
                        }}
                        disabled={!this.validStepOne() || loading}
                      >
                        GET QUOTATIONS
                        <i className="mdi mdi-arrow-down-bold ml-2"></i>
                      </Button>
                    </div>

                    {this.state.loading === false &&
                      this.state.apierror.length > 0 && (
                        <p className="error-message">{this.state.apierror}</p>
                      )}
                  </div>
                </Col>
              </Row>
              <Row id={"mainquote"}>
                <Col lg={4} sm={12} className={"sellingPrice "}></Col>
                <PriceView
                  sellingPrice={sellingPrice || 0}
                  discount={discount}
                  loading={loading}
                  disabled={
                    typeof sellingPrice === "undefined" ||
                    sellingPrice === null ||
                    sellingPrice === 0 ||
                    loading
                  }
                  trucktype={truckType}
                  showButton={false}
                  acceptOffer={() => {
                    console.log("acceptOffer");
                    this.setState({ acceptedOffer: "sellingPrice" }, () => {
                      if (window.document.getElementById("makebooking"))
                        window.document
                          .getElementById("makebooking")
                          .scrollIntoView();
                    });
                  }}
                />
                <button
                  className="sell-nextbutton"
                  color="primary"
                  onClick={() => {
                    this.acceptQuote();
                  }}
                  disabled={!this.validStepOne() || this.state.loading}
                >
                  Next
                  <i className="mdi mdi-arrow-right ml-2"></i>
                </button>
                <Col lg={4} sm={12} className={"sellingPrice "}></Col>
              </Row>
              {this.state.fullQuote.tripDistance > 0 && (
                <p className="trip-info">
                  <span>
                    {Math.round(this.state.fullQuote.tripDistance / 1000)} km
                    for{" "}
                  </span>
                  <span>
                    {Math.round(this.state.fullQuote.tripDuration / 60)} minutes
                    <i className="mdi mdi-star ml-2"></i>
                  </span>{" "}
                  once off trip.
                </p>
              )}
              {otherOptions && (
                <Row className="otherOptions  padding-top">
                  <h6>Offers</h6>
                  <Col lg={2} md={1} sm={0} className={"sellingPrice "}></Col>
                  <Col
                    lg={8}
                    md={10}
                    sm={12}
                    className={"sellingPrice pricepreview-parent"}
                  >
                    {otherOptions.map((option) => {
                      return (
                        <PriceMiniView
                          key={option.truckType}
                          option={option}
                          disabled={
                            typeof sellingPrice === "undefined" ||
                            sellingPrice === null ||
                            sellingPrice === 0 ||
                            loading
                          }
                          getQuote={() => {
                            this.setState(
                              { truckType: option.truckType },
                              () => {
                                this.getQuote();
                              }
                            );
                          }}
                        />
                      );
                    })}
                  </Col>
                  <Col lg={2} md={1} sm={0} className={"sellingPrice "}></Col>
                </Row>
              )}
            </div>

            <h3 className="font-18">Customer reviews</h3>
            <Marquee
              gradient={true}
              gradientWidth={0}
              speed={6}
              direction="right"
            >
              {reviews.map((a, index) => (
                <div key={index}>
                  <div className="home-reviews">
                    <h1>{a.heading}</h1>
                    <div className="date-article-added">
                      <i className="mdi mdi-clock-outline ml-2"></i>
                      {a.date}
                      <i className="mdi mdi-pin ml-2"></i>
                      {a.location}
                      {Array.from({ length: a.category }).map((x, index) => (
                        <i className="mdi mdi-star" key={index}></i>
                      ))}
                    </div>
                    <p>{a.copy}</p>
                  </div>
                </div>
              ))}
            </Marquee>
            <br />
            <div className="instant-quotations-parent">
              <Button
                className="instant-quotations"
                color="primary"
                onClick={() => {
                  window.open(
                    "tel:" + this.state.prospectmobilenumber,
                    "_blank"
                  );
                }}
                disabled={!this.validStepOne() || loading}
              >
                CALL CUSTOMER
                <i className="mdi mdi-cellphone ml-2"></i>
              </Button>
              <br />
              <br />
              <Button
                className="instant-quotations"
                color="secondary"
                onClick={() => {
                  this.driverCancelOrder();
                }}
              >
                Pass the job to another driver
                <i className="mdi mdi-arrow-right ml-2"></i>
              </Button>
              <br />
              <br />
              <Button
                className="instant-quotations"
                color="danger"
                onClick={() => {
                  this.driverCancelOrder();
                }}
              >
                Cancel this trip. Can not be reversed.
                <i className="mdi mdi-cancel ml-2"></i>
              </Button>
            </div>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Section;
