import React from "react";
import { Container, Row, Col } from "reactstrap";
import { Helmet } from "react-helmet";
import Footer from "../../components/Footer/footer";

export default function GITInsurance() {
  return (
    <React.Fragment>
      <Helmet>
        <title>Goods In Transit (GIT) Insurance | QikTruck Driver Guide</title>
        <meta
          name="description"
          content="Learn about Goods In Transit insurance that protects customer cargo while being transported by QikTruck drivers."
        />
      </Helmet>

      <section className="section bg-light pt-5">
        <Container>
          <Row className="justify-content-center">
            <Col lg={8}>
              <div className="section-title text-center mb-5">
                <h1 className="font-weight-bold">Goods In Transit Insurance</h1>
                <p className="text-muted">
                  Understanding coverage for the cargo you transport
                </p>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg={8} className="mx-auto">
              <div className="card shadow-sm rounded p-4 mb-4">
                <div className="d-flex align-items-center mb-4">
                  <div className="bg-primary rounded p-3 mr-4">
                    <i className="fa fa-truck text-white h3 mb-0"></i>
                  </div>
                  <div>
                    <h3 className="mb-1">What is GIT Insurance?</h3>
                    <p className="text-muted mb-0">Coverage that protects customer cargo</p>
                  </div>
                </div>
                <div className="mb-4">
                  <p>
                    Goods In Transit (GIT) insurance provides protection for customer property while it's 
                    being transported from one location to another. As a QikTruck driver, understanding 
                    this coverage is essential to your role.
                  </p>
                </div>
                <div className="row">
                  <div className="col-md-6 mb-3">
                    <div className="card h-100 border-primary">
                      <div className="card-header bg-primary text-white">
                        <h5 className="mb-0">What's Covered</h5>
                      </div>
                      <div className="card-body">
                        <ul className="list-unstyled">
                          <li className="mb-2">
                            <i className="fa fa-check-circle text-success mr-2"></i>
                            Damage during loading/unloading
                          </li>
                          <li className="mb-2">
                            <i className="fa fa-check-circle text-success mr-2"></i>
                            Damage during transport
                          </li>
                          <li className="mb-2">
                            <i className="fa fa-check-circle text-success mr-2"></i>
                            Theft while in your vehicle
                          </li>
                          <li className="mb-2">
                            <i className="fa fa-check-circle text-success mr-2"></i>
                            Fire, flood, or collision damage
                          </li>
                          <li>
                            <i className="fa fa-check-circle text-success mr-2"></i>
                            Accidental breakage
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <div className="card h-100 border-danger">
                      <div className="card-header bg-danger text-white">
                        <h5 className="mb-0">What's Not Covered</h5>
                      </div>
                      <div className="card-body">
                        <ul className="list-unstyled">
                          <li className="mb-2">
                            <i className="fa fa-times-circle text-danger mr-2"></i>
                            Improper packaging by customer
                          </li>
                          <li className="mb-2">
                            <i className="fa fa-times-circle text-danger mr-2"></i>
                            Inherent vice (items that deteriorate naturally)
                          </li>
                          <li className="mb-2">
                            <i className="fa fa-times-circle text-danger mr-2"></i>
                            Delay damages (unless due to insured peril)
                          </li>
                          <li className="mb-2">
                            <i className="fa fa-times-circle text-danger mr-2"></i>
                            Negligence by the driver
                          </li>
                          <li>
                            <i className="fa fa-times-circle text-danger mr-2"></i>
                            Prohibited items (see section below)
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="alert alert-info mt-3">
                  <strong>QikTruck Driver Benefit:</strong> All active QikTruck drivers are provided with 
                  GIT insurance coverage up to R50,000 per shipment at no additional cost to you.
                </div>
              </div>

              <div className="card shadow-sm rounded p-4 mb-4">
                <div className="d-flex align-items-center mb-4">
                  <div className="bg-warning rounded p-3 mr-4">
                    <i className="fa fa-exclamation-triangle text-white h3 mb-0"></i>
                  </div>
                  <div>
                    <h3 className="mb-1">Prohibited Items</h3>
                    <p className="text-muted mb-0">Items that are not covered by GIT insurance</p>
                  </div>
                </div>
                <p>
                  The following items are not covered by QikTruck's GIT insurance and should not be 
                  transported by drivers:
                </p>
                <div className="row">
                  <div className="col-md-6">
                    <div className="bg-light p-3 rounded mb-3">
                      <h5>High-Value Items</h5>
                      <ul>
                        <li>Cash or currency</li>
                        <li>Precious metals and stones</li>
                        <li>Jewelry exceeding R5,000 in value</li>
                        <li>Artwork exceeding R10,000 in value</li>
                        <li>Collectibles of high value</li>
                        <li>Original documents or securities</li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="bg-light p-3 rounded mb-3">
                      <h5>Dangerous Goods</h5>
                      <ul>
                        <li>Explosives or fireworks</li>
                        <li>Flammable gases or liquids</li>
                        <li>Toxic or infectious substances</li>
                        <li>Radioactive materials</li>
                        <li>Corrosives</li>
                        <li>Illegal substances or items</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="alert alert-warning">
                  <strong>Important:</strong> If a customer attempts to ship prohibited items, politely 
                  explain that these cannot be transported under our insurance policy. Contact support 
                  if you need assistance.
                </div>
              </div>

              <div className="card shadow-sm rounded p-4 mb-4">
                <div className="d-flex align-items-center mb-4">
                  <div className="bg-success rounded p-3 mr-4">
                    <i className="fa fa-shield text-white h3 mb-0"></i>
                  </div>
                  <div>
                    <h3 className="mb-1">Best Practices for Cargo Protection</h3>
                    <p className="text-muted mb-0">How to minimize the risk of claims</p>
                  </div>
                </div>
                <p>
                  Follow these guidelines to protect cargo and minimize the risk of insurance claims:
                </p>
                <div className="table-responsive mb-3">
                  <table className="table table-bordered">
                    <thead className="bg-light">
                      <tr>
                        <th>Stage</th>
                        <th>Best Practices</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Before Pickup</td>
                        <td>
                          <ul className="mb-0">
                            <li>Ensure your vehicle is clean and dry</li>
                            <li>Have appropriate cargo securing materials ready</li>
                            <li>Plan your route to minimize risks</li>
                          </ul>
                        </td>
                      </tr>
                      <tr>
                        <td>During Pickup</td>
                        <td>
                          <ul className="mb-0">
                            <li>Inspect items for pre-existing damage</li>
                            <li>Document condition with photos in the app</li>
                            <li>Refuse inadequately packaged items</li>
                            <li>Confirm item count matches order details</li>
                          </ul>
                        </td>
                      </tr>
                      <tr>
                        <td>During Transport</td>
                        <td>
                          <ul className="mb-0">
                            <li>Secure items properly to prevent shifting</li>
                            <li>Drive cautiously, avoiding sudden stops</li>
                            <li>Keep vehicle locked when unattended</li>
                            <li>Maintain appropriate climate conditions if required</li>
                          </ul>
                        </td>
                      </tr>
                      <tr>
                        <td>During Delivery</td>
                        <td>
                          <ul className="mb-0">
                            <li>Handle items carefully during unloading</li>
                            <li>Have customer inspect items before accepting</li>
                            <li>Document delivery condition with delivery photos</li>
                            <li>Get customer signature in the app</li>
                          </ul>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="bg-light p-3 rounded">
                  <h5>Load Securing Guidelines</h5>
                  <div className="row">
                    <div className="col-md-4 text-center mb-3">
                      <div className="bg-white p-2 rounded">
                        <i className="fa fa-arrows text-success mb-2 fa-2x"></i>
                        <p className="mb-0">
                          <strong>Prevent Movement</strong><br />
                          Items should not slide, tip, or shift
                        </p>
                      </div>
                    </div>
                    <div className="col-md-4 text-center mb-3">
                      <div className="bg-white p-2 rounded">
                        <i className="fa fa-balance-scale text-success mb-2 fa-2x"></i>
                        <p className="mb-0">
                          <strong>Balance Weight</strong><br />
                          Distribute load evenly across the vehicle
                        </p>
                      </div>
                    </div>
                    <div className="col-md-4 text-center mb-3">
                      <div className="bg-white p-2 rounded">
                        <i className="fa fa-cubes text-success mb-2 fa-2x"></i>
                        <p className="mb-0">
                          <strong>Layer Properly</strong><br />
                          Heavy at bottom, fragile on top
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card shadow-sm rounded p-4 mb-4">
                <div className="d-flex align-items-center mb-4">
                  <div className="bg-info rounded p-3 mr-4">
                    <i className="fa fa-file-text-o text-white h3 mb-0"></i>
                  </div>
                  <div>
                    <h3 className="mb-1">Claims Process for Cargo Damage</h3>
                    <p className="text-muted mb-0">What to do when something goes wrong</p>
                  </div>
                </div>
                <p>
                  If cargo is damaged or lost during transport, follow these steps immediately:
                </p>
                <div className="row">
                  <div className="col-lg-6 mb-3">
                    <div className="card h-100">
                      <div className="card-body">
                        <h5 className="card-title">When Customer Reports Damage At Delivery</h5>
                        <ol className="pl-3">
                          <li>Acknowledge the issue professionally</li>
                          <li>Document damage with clear photos</li>
                          <li>Use the "Report Incident" feature in the app</li>
                          <li>Complete incident details including nature of damage</li>
                          <li>Note any extenuating circumstances</li>
                          <li>Provide customer with claim reference number</li>
                        </ol>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 mb-3">
                    <div className="card h-100">
                      <div className="card-body">
                        <h5 className="card-title">When Damage Occurs During Transport</h5>
                        <ol className="pl-3">
                          <li>Secure the items to prevent further damage</li>
                          <li>Take photos of the damage and situation</li>
                          <li>Contact QikTruck support immediately</li>
                          <li>Follow instructions from support team</li>
                          <li>Inform customer before arrival if possible</li>
                          <li>Complete incident report with all details</li>
                        </ol>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="alert alert-danger mt-3">
                  <strong>Time Sensitive!</strong> All damage or loss must be reported within 4 hours of 
                  occurrence or delivery. Failing to report promptly may result in claim denial.
                </div>
              </div>

              <div className="card shadow-sm rounded p-4 mb-4">
                <div className="d-flex align-items-center mb-4">
                  <div className="bg-dark rounded p-3 mr-4">
                    <i className="fa fa-question-circle text-white h3 mb-0"></i>
                  </div>
                  <div>
                    <h3 className="mb-1">Frequently Asked Questions</h3>
                    <p className="text-muted mb-0">Common questions about GIT insurance</p>
                  </div>
                </div>
                <div className="accordion" id="gitFAQ">
                  <div className="card mb-2">
                    <div className="card-header" id="headingOne">
                      <h5 className="mb-0">
                        <button className="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseOne">
                          Am I personally liable for damaged goods?
                        </button>
                      </h5>
                    </div>
                    <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#gitFAQ">
                      <div className="card-body">
                        If you've followed all proper procedures and the damage is covered by GIT insurance, 
                        you are not personally liable. However, if damage occurs due to negligence or violation 
                        of company policies, you may be responsible for the insurance deductible or full cost 
                        in cases of policy exclusions.
                      </div>
                    </div>
                  </div>
                  <div className="card mb-2">
                    <div className="card-header" id="headingTwo">
                      <h5 className="mb-0">
                        <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo">
                          What's the maximum coverage per shipment?
                        </button>
                      </h5>
                    </div>
                    <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#gitFAQ">
                      <div className="card-body">
                        QikTruck's standard GIT insurance covers up to R50,000 per shipment. Customers who need 
                        additional coverage for high-value items can purchase supplemental insurance during the 
                        booking process. The app will indicate when a delivery has supplemental insurance.
                      </div>
                    </div>
                  </div>
                  <div className="card mb-2">
                    <div className="card-header" id="headingThree">
                      <h5 className="mb-0">
                        <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseThree">
                          How does the claims process affect my driver rating?
                        </button>
                      </h5>
                    </div>
                    <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#gitFAQ">
                      <div className="card-body">
                        Occasional cargo claims that are properly reported won't significantly impact your rating. 
                        However, multiple claims in a short period or claims involving driver negligence may affect 
                        your standing. The best approach is to follow best practices and report any issues promptly 
                        and honestly.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <Footer />
    </React.Fragment>
  );
} 