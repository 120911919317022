import React from "react";
import { Container, Row, Col } from "reactstrap";
import { Helmet } from "react-helmet";
import Footer from "../../components/Footer/footer";

export default function IncidentManagement() {
  return (
    <React.Fragment>
      <Helmet>
        <title>Incident Management for Drivers | QikTruck</title>
        <meta
          name="description"
          content="Learn how to handle incidents, accidents, and delays during deliveries. Follow our guidelines to ensure proper reporting and resolution."
        />
      </Helmet>

      <section className="section bg-light pt-5">
        <Container>
          <Row className="justify-content-center">
            <Col lg={8}>
              <div className="section-title text-center mb-5">
                <h1 className="font-weight-bold">Incident Management for Drivers</h1>
                <p className="text-muted">
                  Guidelines for handling accidents, delays, and incidents during deliveries
                </p>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg={8} className="mx-auto">
              <div className="card shadow-sm rounded p-4 mb-4">
                <div className="d-flex align-items-center mb-4">
                  <div className="bg-primary rounded p-3 mr-4">
                    <i className="fa fa-exclamation-triangle text-white h3 mb-0"></i>
                  </div>
                  <div>
                    <h3 className="mb-1">What to Do in Case of an Accident</h3>
                    <p className="text-muted mb-0">Follow these steps immediately</p>
                  </div>
                </div>
                <ul className="list-unstyled">
                  <li className="mb-3">
                    <div className="d-flex">
                      <div className="badge badge-primary mr-3 mt-1">1</div>
                      <div>
                        <h5 className="mb-1">Ensure Safety First</h5>
                        <p>Check for injuries and ensure everyone is safe. Move to a safe location if possible.</p>
                      </div>
                    </div>
                  </li>
                  <li className="mb-3">
                    <div className="d-flex">
                      <div className="badge badge-primary mr-3 mt-1">2</div>
                      <div>
                        <h5 className="mb-1">Contact Emergency Services</h5>
                        <p>Call emergency services if needed at 10111 or 10177 for ambulance.</p>
                      </div>
                    </div>
                  </li>
                  <li className="mb-3">
                    <div className="d-flex">
                      <div className="badge badge-primary mr-3 mt-1">3</div>
                      <div>
                        <h5 className="mb-1">Notify QikTruck Support</h5>
                        <p>Contact support immediately via the app or call our emergency hotline.</p>
                      </div>
                    </div>
                  </li>
                  <li className="mb-3">
                    <div className="d-flex">
                      <div className="badge badge-primary mr-3 mt-1">4</div>
                      <div>
                        <h5 className="mb-1">Document the Incident</h5>
                        <p>Take photos of any damage, exchange details with other parties involved.</p>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="d-flex">
                      <div className="badge badge-primary mr-3 mt-1">5</div>
                      <div>
                        <h5 className="mb-1">File a Report</h5>
                        <p>Complete an incident report through the QikTruck app within 24 hours.</p>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>

              <div className="card shadow-sm rounded p-4 mb-4">
                <div className="d-flex align-items-center mb-4">
                  <div className="bg-warning rounded p-3 mr-4">
                    <i className="fa fa-clock-o text-white h3 mb-0"></i>
                  </div>
                  <div>
                    <h3 className="mb-1">Handling Delivery Delays</h3>
                    <p className="text-muted mb-0">Proactive steps to manage delays</p>
                  </div>
                </div>
                <div className="mb-4">
                  <p>
                    Delivery delays can happen for many reasons including traffic, weather, or vehicle issues. 
                    How you handle these situations can make a significant difference in customer satisfaction.
                  </p>
                </div>
                <ul className="list-unstyled">
                  <li className="mb-3">
                    <div className="d-flex">
                      <div className="badge badge-warning mr-3 mt-1">1</div>
                      <div>
                        <h5 className="mb-1">Communicate Early</h5>
                        <p>Notify the customer through the app as soon as you anticipate a delay.</p>
                      </div>
                    </div>
                  </li>
                  <li className="mb-3">
                    <div className="d-flex">
                      <div className="badge badge-warning mr-3 mt-1">2</div>
                      <div>
                        <h5 className="mb-1">Provide a New ETA</h5>
                        <p>Give a realistic updated arrival time rather than vague estimates.</p>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="d-flex">
                      <div className="badge badge-warning mr-3 mt-1">3</div>
                      <div>
                        <h5 className="mb-1">Update QikTruck Support</h5>
                        <p>Inform support about significant delays so they can assist if needed.</p>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>

              <div className="card shadow-sm rounded p-4 mb-4">
                <div className="d-flex align-items-center mb-4">
                  <div className="bg-success rounded p-3 mr-4">
                    <i className="fa fa-check-circle text-white h3 mb-0"></i>
                  </div>
                  <div>
                    <h3 className="mb-1">Incident Reporting Procedures</h3>
                    <p className="text-muted mb-0">Complete documentation for all incidents</p>
                  </div>
                </div>
                <p>
                  Proper documentation of all incidents is essential for insurance purposes and to help 
                  QikTruck improve our services. Follow these guidelines for all incident reports:
                </p>
                <div className="bg-light p-3 rounded mb-3">
                  <h5>Required Information</h5>
                  <ul>
                    <li>Date, time, and location of incident</li>
                    <li>Weather and road conditions</li>
                    <li>Description of what happened</li>
                    <li>Photos of any damage or scene</li>
                    <li>Contact information of any witnesses or involved parties</li>
                    <li>Police case number (if applicable)</li>
                  </ul>
                </div>
                <p>
                  <strong>Note:</strong> All incidents must be reported within 24 hours, even if they seem minor.
                  Failure to report incidents may affect insurance coverage and your standing with QikTruck.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <Footer />
    </React.Fragment>
  );
} 