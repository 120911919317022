import React from "react";
import { Container, Row, Col } from "reactstrap";
import { Helmet } from "react-helmet";
import Footer from "../../components/Footer/footer";

export default function Payments() {
  return (
    <React.Fragment>
      <Helmet>
        <title>How Payments Work | QikTruck Driver Guide</title>
        <meta
          name="description"
          content="Understand payment cycles, commission structure, and how to track your earnings as a QikTruck driver."
        />
      </Helmet>

      <section className="section bg-light pt-5">
        <Container>
          <Row className="justify-content-center">
            <Col lg={8}>
              <div className="section-title text-center mb-5">
                <h1 className="font-weight-bold">How Payments Work</h1>
                <p className="text-muted">
                  Understanding your earnings, payment cycles, and commission structure
                </p>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg={8} className="mx-auto">
              <div className="card shadow-sm rounded p-4 mb-4">
                <div className="d-flex align-items-center mb-4">
                  <div className="bg-primary rounded p-3 mr-4">
                    <i className="fa fa-money text-white h3 mb-0"></i>
                  </div>
                  <div>
                    <h3 className="mb-1">Payment Schedule</h3>
                    <p className="text-muted mb-0">When and how you'll receive your earnings</p>
                  </div>
                </div>
                <div className="mb-4">
                  <p>
                    QikTruck drivers are paid on a weekly basis. All completed deliveries from Monday to 
                    Sunday will be processed and paid out the following week.
                  </p>
                </div>
                <ul className="list-unstyled">
                  <li className="mb-3">
                    <div className="d-flex">
                      <span className="badge badge-primary mr-3 mt-1">1</span>
                      <div>
                        <h5 className="mb-1">Weekly Payment Cycle</h5>
                        <p>Payments are processed every Tuesday for the previous week's deliveries</p>
                      </div>
                    </div>
                  </li>
                  <li className="mb-3">
                    <div className="d-flex">
                      <span className="badge badge-primary mr-3 mt-1">2</span>
                      <div>
                        <h5 className="mb-1">Direct Deposit</h5>
                        <p>Funds are transferred directly to your registered bank account</p>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="d-flex">
                      <span className="badge badge-primary mr-3 mt-1">3</span>
                      <div>
                        <h5 className="mb-1">Payment Notification</h5>
                        <p>You'll receive an SMS and app notification when payment has been processed</p>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>

              <div className="card shadow-sm rounded p-4 mb-4">
                <div className="d-flex align-items-center mb-4">
                  <div className="bg-success rounded p-3 mr-4">
                    <i className="fa fa-pie-chart text-white h3 mb-0"></i>
                  </div>
                  <div>
                    <h3 className="mb-1">Commission Structure</h3>
                    <p className="text-muted mb-0">How your earnings are calculated</p>
                  </div>
                </div>
                <div className="mb-4">
                  <p>
                    QikTruck uses a transparent commission structure that ensures you earn a fair amount 
                    for every delivery completed.
                  </p>
                </div>
                <div className="table-responsive">
                  <table className="table table-bordered">
                    <thead className="bg-light">
                      <tr>
                        <th>Delivery Type</th>
                        <th>Base Rate</th>
                        <th>Driver Commission</th>
                        <th>Distance Bonus</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Standard Delivery</td>
                        <td>Varies by distance</td>
                        <td>75% of delivery fee</td>
                        <td>+R5/km after 10km</td>
                      </tr>
                      <tr>
                        <td>Express Delivery</td>
                        <td>1.5x Standard Rate</td>
                        <td>80% of delivery fee</td>
                        <td>+R7/km after 10km</td>
                      </tr>
                      <tr>
                        <td>Heavy Items</td>
                        <td>2x Standard Rate</td>
                        <td>75% of delivery fee</td>
                        <td>+R10/km after 10km</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <p className="mt-3">
                  <strong>Note:</strong> Additional bonuses may apply during peak times, special promotions, 
                  or in high-demand areas.
                </p>
              </div>

              <div className="card shadow-sm rounded p-4 mb-4">
                <div className="d-flex align-items-center mb-4">
                  <div className="bg-warning rounded p-3 mr-4">
                    <i className="fa fa-line-chart text-white h3 mb-0"></i>
                  </div>
                  <div>
                    <h3 className="mb-1">Tracking Your Earnings</h3>
                    <p className="text-muted mb-0">How to monitor and verify your payments</p>
                  </div>
                </div>
                <p>
                  The QikTruck app provides several tools to help you track your earnings in real-time 
                  and review your payment history:
                </p>
                <div className="row">
                  <div className="col-md-6 mb-3">
                    <div className="card h-100">
                      <div className="card-body">
                        <h5 className="card-title">Daily Earnings Summary</h5>
                        <p className="card-text">View your earnings for the current day, including a breakdown by delivery</p>
                        <small className="text-muted">Found in the "Earnings" tab of your driver app</small>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <div className="card h-100">
                      <div className="card-body">
                        <h5 className="card-title">Weekly Statement</h5>
                        <p className="card-text">Detailed weekly report of all deliveries, fees, bonuses, and deductions</p>
                        <small className="text-muted">Emailed to you each Tuesday and available in app</small>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <div className="card h-100">
                      <div className="card-body">
                        <h5 className="card-title">Payment History</h5>
                        <p className="card-text">Access your complete payment history dating back to when you started</p>
                        <small className="text-muted">Available in the "Payment History" section</small>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <div className="card h-100">
                      <div className="card-body">
                        <h5 className="card-title">Earnings Calculator</h5>
                        <p className="card-text">Estimate your earnings based on planned deliveries and routes</p>
                        <small className="text-muted">Available in the "Tools" section of your app</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
              <div className="alert alert-info" role="alert">
                <h5 className="alert-heading">Need Help With Payments?</h5>
                <p>If you have any questions about your payments or notice discrepancies in your earnings, 
                please contact our driver support team through the app or via WhatsApp at 27663784460.</p>
                <hr />
                <p className="mb-0">Support is available Monday to Friday, 8am to 5pm.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <Footer />
    </React.Fragment>
  );
} 