import React from "react";
import { Container, Row, Col } from "reactstrap";
import { Helmet } from "react-helmet";
import Footer from "../../components/Footer/footer";

export default function SafetyGuidelines() {
  return (
    <React.Fragment>
      <Helmet>
        <title>Safety Guidelines | QikTruck Driver Guide</title>
        <meta
          name="description"
          content="Essential safety protocols for QikTruck drivers - learn proper loading, driving, and delivery safety practices."
        />
      </Helmet>

      <section className="section bg-light pt-5">
        <Container>
          <Row className="justify-content-center">
            <Col lg={8}>
              <div className="section-title text-center mb-5">
                <h1 className="font-weight-bold">Safety Guidelines</h1>
                <p className="text-muted">
                  Essential protocols for safe loading, driving, and delivering
                </p>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg={8} className="mx-auto">
              <div className="card shadow-sm rounded p-4 mb-4">
                <div className="d-flex align-items-center mb-4">
                  <div className="bg-danger rounded p-3 mr-4">
                    <i className="fa fa-shield text-white h3 mb-0"></i>
                  </div>
                  <div>
                    <h3 className="mb-1">Road Safety</h3>
                    <p className="text-muted mb-0">Safe driving practices for delivery drivers</p>
                  </div>
                </div>
                <div className="mb-4">
                  <p>
                    As a QikTruck driver, you represent our brand on the road. Following safe driving 
                    practices is not only essential for your safety but also for maintaining our reputation.
                  </p>
                </div>
                <div className="row">
                  <div className="col-md-6 mb-3">
                    <div className="card border-danger h-100">
                      <div className="card-header bg-danger text-white">
                        <h5 className="mb-0">Do's</h5>
                      </div>
                      <div className="card-body">
                        <ul className="pl-3">
                          <li>Always wear your seatbelt</li>
                          <li>Observe speed limits at all times</li>
                          <li>Maintain a safe following distance</li>
                          <li>Use indicators when changing lanes</li>
                          <li>Keep both hands on the wheel</li>
                          <li>Take regular breaks on long routes</li>
                          <li>Check blind spots before turning</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <div className="card border-dark h-100">
                      <div className="card-header bg-dark text-white">
                        <h5 className="mb-0">Don'ts</h5>
                      </div>
                      <div className="card-body">
                        <ul className="pl-3">
                          <li>Never use your phone while driving</li>
                          <li>Don't drive under the influence</li>
                          <li>Avoid eating while driving</li>
                          <li>Don't drive when fatigued</li>
                          <li>Never rush to meet deadlines</li>
                          <li>Don't overload your vehicle</li>
                          <li>Avoid aggressive driving behaviors</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card shadow-sm rounded p-4 mb-4">
                <div className="d-flex align-items-center mb-4">
                  <div className="bg-primary rounded p-3 mr-4">
                    <i className="fa fa-cubes text-white h3 mb-0"></i>
                  </div>
                  <div>
                    <h3 className="mb-1">Loading & Unloading Safety</h3>
                    <p className="text-muted mb-0">Proper handling techniques to prevent injuries</p>
                  </div>
                </div>
                <p>
                  Many delivery-related injuries occur during loading and unloading. Follow these guidelines 
                  to prevent strain, falls, and other common injuries:
                </p>
                <div className="bg-light p-3 rounded mb-3">
                  <h5>Proper Lifting Technique</h5>
                  <div className="row">
                    <div className="col-md-4 text-center mb-3">
                      <div className="bg-white p-2 rounded">
                        <i className="fa fa-arrow-down fa-2x text-success mb-2"></i>
                        <p className="mb-0"><strong>Step 1:</strong> Bend at knees, not waist</p>
                      </div>
                    </div>
                    <div className="col-md-4 text-center mb-3">
                      <div className="bg-white p-2 rounded">
                        <i className="fa fa-arrow-up fa-2x text-success mb-2"></i>
                        <p className="mb-0"><strong>Step 2:</strong> Lift with legs, keep back straight</p>
                      </div>
                    </div>
                    <div className="col-md-4 text-center mb-3">
                      <div className="bg-white p-2 rounded">
                        <i className="fa fa-users fa-2x text-success mb-2"></i>
                        <p className="mb-0"><strong>Step 3:</strong> Get help for items over 25kg</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mb-3">
                  <h5>Loading Best Practices</h5>
                  <ul>
                    <li>Always use loading equipment when available (dollies, hand trucks)</li>
                    <li>Secure cargo properly to prevent shifting during transport</li>
                    <li>Load heaviest items at the bottom and lighter items on top</li>
                    <li>Distribute weight evenly throughout the vehicle</li>
                    <li>Use proper straps, ties, or bungee cords to secure items</li>
                    <li>Never exceed your vehicle's weight capacity</li>
                  </ul>
                </div>
              </div>

              <div className="card shadow-sm rounded p-4 mb-4">
                <div className="d-flex align-items-center mb-4">
                  <div className="bg-warning rounded p-3 mr-4">
                    <i className="fa fa-user-secret text-white h3 mb-0"></i>
                  </div>
                  <div>
                    <h3 className="mb-1">Personal Safety</h3>
                    <p className="text-muted mb-0">Staying safe during pickups and deliveries</p>
                  </div>
                </div>
                <p>
                  Your personal safety is our top priority. Be aware of your surroundings and follow 
                  these guidelines while making deliveries:
                </p>
                <div className="alert alert-warning">
                  <strong>Important:</strong> If you ever feel unsafe during a pickup or delivery, 
                  contact QikTruck support immediately at 27663784460.
                </div>
                <div className="mb-3">
                  <h5>Safety Tips for Deliveries</h5>
                  <ol>
                    <li>Keep your phone charged and easily accessible at all times</li>
                    <li>Share your location with a trusted contact during late night deliveries</li>
                    <li>Park in well-lit areas whenever possible</li>
                    <li>Lock your vehicle when making deliveries, even for short drops</li>
                    <li>Be cautious in unfamiliar or high-risk areas</li>
                    <li>Trust your instincts - if something feels wrong, proceed with caution</li>
                    <li>Report any safety concerns or incidents to QikTruck support</li>
                  </ol>
                </div>
              </div>

              <div className="card shadow-sm rounded p-4 mb-4">
                <div className="d-flex align-items-center mb-4">
                  <div className="bg-info rounded p-3 mr-4">
                    <i className="fa fa-tachometer text-white h3 mb-0"></i>
                  </div>
                  <div>
                    <h3 className="mb-1">Vehicle Safety Checklist</h3>
                    <p className="text-muted mb-0">Daily inspections to ensure your vehicle is road-ready</p>
                  </div>
                </div>
                <p>
                  Perform these basic checks before starting your delivery route each day:
                </p>
                <div className="table-responsive">
                  <table className="table table-bordered">
                    <thead className="bg-light">
                      <tr>
                        <th>Check</th>
                        <th>What to Look For</th>
                        <th>Action if Problem</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Tires</td>
                        <td>Proper inflation, tread wear, damage</td>
                        <td>Inflate/replace as needed</td>
                      </tr>
                      <tr>
                        <td>Lights</td>
                        <td>Headlights, brake lights, indicators</td>
                        <td>Replace bulbs or report electrical issues</td>
                      </tr>
                      <tr>
                        <td>Fluids</td>
                        <td>Oil, coolant, brake fluid levels</td>
                        <td>Top up or service if low</td>
                      </tr>
                      <tr>
                        <td>Brakes</td>
                        <td>Responsiveness, unusual sounds</td>
                        <td>Request immediate service</td>
                      </tr>
                      <tr>
                        <td>Wipers</td>
                        <td>Condition and operation</td>
                        <td>Replace if worn</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <p className="mt-3">
                  <strong>Remember:</strong> If your vehicle isn't safe to drive, report it immediately through 
                  the QikTruck app. Never drive an unsafe vehicle.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <Footer />
    </React.Fragment>
  );
} 