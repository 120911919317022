import React from "react";
import { Container, Row, Col } from "reactstrap";
import { Helmet } from "react-helmet";
import Footer from "../../components/Footer/footer";

export default function VehicleMaintenance() {
  return (
    <React.Fragment>
      <Helmet>
        <title>Vehicle Maintenance | QikTruck Driver Guide</title>
        <meta
          name="description"
          content="Keep your truck in optimal condition with preventative maintenance schedules and essential care tips for QikTruck drivers."
        />
      </Helmet>

      <section className="section bg-light pt-5">
        <Container>
          <Row className="justify-content-center">
            <Col lg={8}>
              <div className="section-title text-center mb-5">
                <h1 className="font-weight-bold">Vehicle Maintenance</h1>
                <p className="text-muted">
                  Keep your truck in optimal condition for safer, more efficient deliveries
                </p>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg={8} className="mx-auto">
              <div className="card shadow-sm rounded p-4 mb-4">
                <div className="d-flex align-items-center mb-4">
                  <div className="bg-primary rounded p-3 mr-4">
                    <i className="fa fa-wrench text-white h3 mb-0"></i>
                  </div>
                  <div>
                    <h3 className="mb-1">Preventative Maintenance Schedule</h3>
                    <p className="text-muted mb-0">Regular maintenance timeline to prevent breakdowns</p>
                  </div>
                </div>
                <div className="mb-4">
                  <p>
                    Following a consistent maintenance schedule helps prevent costly repairs and keeps 
                    your vehicle running safely and efficiently. Below is the recommended schedule for QikTruck 
                    drivers:
                  </p>
                </div>
                <div className="table-responsive">
                  <table className="table table-bordered">
                    <thead className="bg-light">
                      <tr>
                        <th>Maintenance Task</th>
                        <th>Timeline</th>
                        <th>Importance</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Oil & Filter Change</td>
                        <td>Every 5,000 km or 3 months</td>
                        <td><span className="badge badge-danger">Critical</span></td>
                      </tr>
                      <tr>
                        <td>Tire Rotation</td>
                        <td>Every 10,000 km</td>
                        <td><span className="badge badge-warning">Important</span></td>
                      </tr>
                      <tr>
                        <td>Brake Inspection</td>
                        <td>Every 15,000 km</td>
                        <td><span className="badge badge-danger">Critical</span></td>
                      </tr>
                      <tr>
                        <td>Air Filter Change</td>
                        <td>Every 20,000 km</td>
                        <td><span className="badge badge-warning">Important</span></td>
                      </tr>
                      <tr>
                        <td>Transmission Service</td>
                        <td>Every 50,000 km</td>
                        <td><span className="badge badge-danger">Critical</span></td>
                      </tr>
                      <tr>
                        <td>Cooling System Flush</td>
                        <td>Every 60,000 km</td>
                        <td><span className="badge badge-warning">Important</span></td>
                      </tr>
                      <tr>
                        <td>Timing Belt Replacement</td>
                        <td>Every 100,000 km</td>
                        <td><span className="badge badge-danger">Critical</span></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="alert alert-info mt-3">
                  <strong>QikTruck Partner Benefit:</strong> Registered QikTruck drivers receive 
                  discounted maintenance services at our partner workshops. Check the app for locations 
                  near you.
                </div>
              </div>

              <div className="card shadow-sm rounded p-4 mb-4">
                <div className="d-flex align-items-center mb-4">
                  <div className="bg-success rounded p-3 mr-4">
                    <i className="fa fa-check-square-o text-white h3 mb-0"></i>
                  </div>
                  <div>
                    <h3 className="mb-1">Daily Vehicle Checks</h3>
                    <p className="text-muted mb-0">Quick inspections to perform before each shift</p>
                  </div>
                </div>
                <p>
                  Taking just 5 minutes before each shift to perform these basic checks can prevent 
                  breakdowns, improve safety, and extend the life of your vehicle:
                </p>
                <div className="row">
                  <div className="col-md-6 mb-3">
                    <div className="card h-100">
                      <div className="card-body">
                        <h5 className="card-title">
                          <i className="fa fa-tint text-primary mr-2"></i>
                          Fluid Levels
                        </h5>
                        <p className="card-text">
                          Check oil, coolant, brake fluid, and windshield washer fluid. Look for leaks 
                          under the vehicle.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <div className="card h-100">
                      <div className="card-body">
                        <h5 className="card-title">
                          <i className="fa fa-car text-primary mr-2"></i>
                          Tire Condition
                        </h5>
                        <p className="card-text">
                          Check pressure in all tires (including spare), inspect tread depth, and look for 
                          any damage or foreign objects.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <div className="card h-100">
                      <div className="card-body">
                        <h5 className="card-title">
                          <i className="fa fa-lightbulb-o text-primary mr-2"></i>
                          Lights & Signals
                        </h5>
                        <p className="card-text">
                          Test headlights, brake lights, turn signals, and hazard lights to ensure they're working properly.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <div className="card h-100">
                      <div className="card-body">
                        <h5 className="card-title">
                          <i className="fa fa-volume-up text-primary mr-2"></i>
                          Unusual Sounds
                        </h5>
                        <p className="card-text">
                          Listen for strange noises during startup and while driving. Report grinding, squealing, or knocking sounds.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-light p-3 rounded mt-2">
                  <h5>Daily Check Documentation</h5>
                  <p>
                    Use the "Vehicle Check" feature in your QikTruck driver app to document your 
                    daily inspections. This creates a record of your diligence and helps flag recurring issues.
                  </p>
                </div>
              </div>

              <div className="card shadow-sm rounded p-4 mb-4">
                <div className="d-flex align-items-center mb-4">
                  <div className="bg-warning rounded p-3 mr-4">
                    <i className="fa fa-exclamation-triangle text-white h3 mb-0"></i>
                  </div>
                  <div>
                    <h3 className="mb-1">Warning Signs to Watch For</h3>
                    <p className="text-muted mb-0">Never ignore these vehicle warning signs</p>
                  </div>
                </div>
                <p>
                  Recognizing these warning signs early can prevent major breakdowns and costly repairs:
                </p>
                <div className="row">
                  <div className="col-md-6">
                    <ul className="list-group mb-3">
                      <li className="list-group-item d-flex align-items-center">
                        <span className="badge badge-danger mr-3">!</span>
                        <span>Dashboard warning lights illuminated</span>
                      </li>
                      <li className="list-group-item d-flex align-items-center">
                        <span className="badge badge-danger mr-3">!</span>
                        <span>Reduced braking effectiveness</span>
                      </li>
                      <li className="list-group-item d-flex align-items-center">
                        <span className="badge badge-danger mr-3">!</span>
                        <span>Fluid leaks under the vehicle</span>
                      </li>
                      <li className="list-group-item d-flex align-items-center">
                        <span className="badge badge-danger mr-3">!</span>
                        <span>Engine overheating</span>
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-6">
                    <ul className="list-group mb-3">
                      <li className="list-group-item d-flex align-items-center">
                        <span className="badge badge-danger mr-3">!</span>
                        <span>Unusual noises (grinding, squealing)</span>
                      </li>
                      <li className="list-group-item d-flex align-items-center">
                        <span className="badge badge-danger mr-3">!</span>
                        <span>Steering difficulty or pulling to one side</span>
                      </li>
                      <li className="list-group-item d-flex align-items-center">
                        <span className="badge badge-danger mr-3">!</span>
                        <span>Excessive exhaust smoke</span>
                      </li>
                      <li className="list-group-item d-flex align-items-center">
                        <span className="badge badge-danger mr-3">!</span>
                        <span>Vibrations or shaking</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="alert alert-warning mt-3">
                  <strong>Important:</strong> If you notice any of these warning signs, report them immediately 
                  through the QikTruck app and arrange for inspection at an authorized service center. 
                  Do not continue driving if it feels unsafe.
                </div>
              </div>

              <div className="card shadow-sm rounded p-4 mb-4">
                <div className="d-flex align-items-center mb-4">
                  <div className="bg-info rounded p-3 mr-4">
                    <i className="fa fa-lightbulb-o text-white h3 mb-0"></i>
                  </div>
                  <div>
                    <h3 className="mb-1">Fuel Efficiency Tips</h3>
                    <p className="text-muted mb-0">Save money and reduce environmental impact</p>
                  </div>
                </div>
                <div className="mb-4">
                  <p>
                    Improving your fuel efficiency not only saves money but also reduces your 
                    environmental impact. Follow these tips to maximize your vehicle's fuel economy:
                  </p>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="bg-light p-3 rounded mb-3">
                      <h5>Driving Habits</h5>
                      <ul>
                        <li>Avoid rapid acceleration and hard braking</li>
                        <li>Maintain a steady speed and use cruise control on highways</li>
                        <li>Reduce idling time – turn off engine if stopped for more than 30 seconds</li>
                        <li>Plan routes to avoid traffic congestion and minimize stops</li>
                        <li>Use the app's navigation to find the most efficient routes</li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="bg-light p-3 rounded mb-3">
                      <h5>Vehicle Maintenance</h5>
                      <ul>
                        <li>Keep tires properly inflated (check weekly)</li>
                        <li>Use the recommended grade of motor oil</li>
                        <li>Replace dirty air filters regularly</li>
                        <li>Remove unnecessary weight from the vehicle</li>
                        <li>Ensure regular engine tune-ups</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <p className="mt-2">
                  <strong>Driver Incentive:</strong> QikTruck rewards drivers with excellent fuel efficiency 
                  through our Green Driver program. Track your fuel economy in the app to qualify for bonuses.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <Footer />
    </React.Fragment>
  );
} 