import Bugsnag from "@bugsnag/js";
import axios from "axios";
import React, { Component } from "react";
import cookie from "react-cookies";
import Marquee from "react-fast-marquee";
import SEO from "react-seo-component";
import util from "../../util";
import { Col, Container, Row } from "reactstrap";
import constants from "../../Constants";
import news from "../articles/news";
import reviews from "../articles/reviews";

const baseURL = constants.baseURL;
class Section extends Component {
  constructor() {
    super();

    let promocode = "";
    try {
      const queryParams = new URLSearchParams(window.location.search);
      promocode = queryParams.get("promocode") || "";
    } catch (error) {
      Bugsnag.notify(error);
    }
    if (cookie.load("qtcid_")) {
      window.location.href = "/customer-truck-hire";
    }

    this.state = {
      loading: false,
      success: false,
      trackYourOrder: false,
      openSuccessModal: false,
      trucktypes: "motorbike",
      apierror: "",
      collectionlat: 0,
      collectionlng: 0,
      fullname: "",
      frequency: "monthly",
      email: "",
      mobilenumber: "",
      companyname: "",
      collectiondate: "",
      collectiontime: "",
      description: "",
      driverrequired: 1,
      openDiscountCode: false,
      promocode: promocode || "",
      vehicletypes: [],
    };
  }

  getVehicleTypes() {
    const { vehicletypes } = this.state;
    if (vehicletypes?.length === 0)
      axios.get(baseURL + "/vehicle-types").then((repos) => {
        const vehicletypesdata = repos.data;
        this.setState({
          vehicletypes: vehicletypesdata?.vehicles,
          vehiclecategories: vehicletypesdata?.categories,
          vehicleconfigurations: vehicletypesdata?.configurations,
        });
      });
  }

  changeTruck() {
    this.setState(
      {
        truckTonnes: undefined,
        trucktypes: "",
        fullQuote: {
          tripDistance: 0,
          tripDuration: 0,
          sellingPrice: 0,
          storedQuoteId: "",
          discount: "",
        },
      },
      () => {
        window.document.getElementById("vehiclelist").scrollIntoView();
      }
    );
  }

  validStepTwo() {
    const {
      trucktypes,
      email,
      mobilenumber,
      companyname,
      fullname,
      collectiondate,
      collectionlat,
      collectionlng,
      description,
      frequency,
    } = this.state;

    return (
      trucktypes.length > 0 &&
      email.length > 0 &&
      mobilenumber.length > 0 &&
      companyname.length > 0 &&
      fullname.length > 0 &&
      collectiondate.length > 0 &&
      collectionlat !== 0 &&
      collectionlng !== 0 &&
      description.length > 0 &&
      frequency.length > 0
    );
  }

  requestQuote() {
    this.setState({
      loading: true,
      apierror: "",
    });
    const {
      trucktypes,
      email,
      mobilenumber,
      companyname,
      fullname,
      collectiondate,
      collectionlat,
      collectionlng,
      description,
      frequency,
      promocode,
      driverrequired,
    } = this.state;

    axios
      .post(baseURL + "/vehicle-hire-quote", {
        trucktypes,
        email,
        mobilenumber,
        companyname,
        fullname,
        collectiondate,
        collectionlat,
        collectionlng,
        description,
        frequency,
        promocode,
        driverrequired,
        source_:
          util.navigator_() + util.source_() + " qtref:" + document?.referrer,
      })
      .then((response) => {
        this.setState({
          loading: false,
          success: response.status === 200,
          openSuccessModal: true,
        });
      })
      .catch((e) => {
        this.setState({
          loading: false,
          apierror: "Request failed. Please try again.",
        });
      });
  }

  getImage(trucktype) {
    try {
      return `https://media.qiktruck.co.za/${trucktype}.png`;
    } catch (error) {
      return "";
    }
  }

  render() {
    const { vehicletypes } = this.state;
    return (
      <React.Fragment>
        <div id={"pagefragmenttop"}></div>
        <SEO
          title={"Hire a truck for your deliveries Today"}
          titleTemplate={"QikTruck"}
          titleSeparator={`-`}
          description={
            "Online quotation today for hire a truck with a diver to transport your goods."
          }
          image={"https://media.qiktruck.co.za/qiktruck-preview-header.png"}
          pathname={"https://qiktruck.co.za/truck-hire"}
          twitterUsername={"qiktrucksa"}
        />
        <section className="hero-section" id={"pagetop"}>
          <Container>
            <div className="store-front">
              <Row>
                <Col lg={12}>
                  <div className="hero-wrapper mb-4">
                    <h2 className="main-headline">
                      Thanks!
                      <p>
                        We received your request for a quotation and your
                        account manager will be in touch!
                      </p>
                    </h2>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <div className="hero-wrapper mb-4">
                    <div id={"vehiclelist"}></div>
                    <Row>
                      <Col lg={12} md={12} className="truck-hire">
                        <h3 className="font-18">
                          <i className="mdi mdi-truck-fast orange"></i> Deliver
                          Faster {"  "}
                        </h3>
                      </Col>
                      <Col lg={6} md={12} className="truck-hire">
                        <p>
                          <i className="mdi mdi-star orange"></i>Vehicles come
                          with a driver and driver app.
                        </p>
                        <p>
                          <i className="mdi mdi-star orange"></i>Vehicle + Order
                          tracking enabled.
                        </p>
                        <p>
                          <i className="mdi mdi-star orange"></i>Free Logistics
                          Management Platform for your business.
                        </p>
                        <p>
                          <i className="mdi mdi-star orange"></i>3 other
                          vehicles on standby.
                        </p>
                        <p>
                          <i className="mdi mdi-star orange"></i>Regular
                          maintenance.
                        </p>
                      </Col>
                      <Col lg={6} md={12} className="truck-hire">
                        <p>
                          <i className="mdi mdi-star orange"></i>Flexible Insurance options.
                        </p>
                        <p>
                          <i className="mdi mdi-star orange"></i>Plus smaller
                          vehicle add-ons for door to door services.
                        </p>
                        <p>
                          <i className="mdi mdi-star orange"></i>Pay for what
                          you use only.
                        </p>
                        <p>
                          <i className="mdi mdi-star orange"></i>Introducing,
                          Move Now Pay Later!
                        </p>
                        <p>
                          <i className="mdi mdi-star orange"></i>24/7 drivers
                          and vehicles available.
                        </p>
                      </Col>
                      <Col lg={12} md={12} className="truck-hire">
                        <h3 className="font-18">
                          <i className="mdi mdi-truck-fast orange"></i>{" "}
                          Distribution Services
                        </h3>
                      </Col>
                      <Col lg={6} md={12} className="truck-hire">
                        <p>
                          <i className="mdi mdi-star orange"></i>Warehouse to
                          Warehouse
                        </p>
                        <p>
                          <i className="mdi mdi-star orange"></i>Warehouse to
                          Shop
                        </p>
                        <p>
                          <i className="mdi mdi-star orange"></i>Warehouse to
                          Customers
                        </p>
                        <p>
                          <i className="mdi mdi-star orange"></i>Shop to
                          Customers
                        </p>
                        <p>
                          <i className="mdi mdi-star orange"></i>Storage to
                          Customers and more!
                        </p>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </div>
            <h3 className="font-18">
              {" "}
              <i className="mdi mdi-emoticon-happy orange"></i> Customer reviews
            </h3>
            <Marquee
              gradient={true}
              gradientWidth={0}
              speed={6}
              direction="right"
            >
              {reviews.map((a, index) => (
                <div key={index}>
                  <div className="home-reviews">
                    <h1>{a.heading}</h1>
                    <div className="date-article-added">
                      <i className="mdi mdi-clock-outline ml-2"></i>
                      {a.date}
                      <i className="mdi mdi-pin ml-2"></i>
                      {a.location}
                      {Array.from({ length: a.category }).map((x, index) => (
                        <i className="mdi mdi-star orange" key={index}></i>
                      ))}
                    </div>
                    <p>{a.copy}</p>
                  </div>
                </div>
              ))}
            </Marquee>
            <br />
            <h3>
              {" "}
              <i className="mdi mdi-earth orange"></i> Where are we located?
            </h3>
            <div className="activity-map">
              <br />
              <img
                alt="qiktruck-activity-map"
                src="https://media.qiktruck.co.za/qiktruck-activity-map.png"
              />{" "}
              <ul className="map-benefits">
                <li>
                  We offer transportation services to all areas in South Africa.
                </li>
                <li>
                  <b>
                    We offer you lower prices because our vehicles already
                    operate in your pickup area.
                  </b>
                </li>
                <li>Each order is tailored to the needs of your load.</li>
                <li>
                  <b>Drivers, Vehicles and Bookings are managed by QikTruck.</b>
                </li>
                <li>
                  Drivers approve quotations and orders on the driver app.
                </li>
                <li>
                  <b>Trucks. Anytime. Quick.</b>
                </li>
              </ul>
            </div>
            <br />
            <h3 className="font-18">
              {" "}
              <i className="mdi mdi-truck-fast orange"></i> In the News
            </h3>
            <Marquee
              gradient={true}
              gradientWidth={0}
              speed={6}
              direction="right"
            >
              {news.map((a, index) => (
                <div key={index}>
                  <a
                    className="home-news"
                    href={"/articles/" + a?.pathname?.toLocaleLowerCase()}
                  >
                    <div
                      className="article-list-image"
                      style={{
                        backgroundImage: `url(https://media.qiktruck.co.za/${a.image})`,
                      }}
                      alt={a.image}
                    />
                    <h1>{a.heading}</h1>
                    <div className="date-article-added">
                      <i className="mdi mdi-clock-outline ml-2"></i>
                      {a.date}
                      <i className="mdi mdi-pin ml-2"></i>
                      {a.category}
                    </div>
                    <p>{a.copy}</p>
                  </a>
                </div>
              ))}
            </Marquee>
            <br />

          </Container>
          <div style={{ display: "none", color: "white", height: 0, width: 0 }}>
            {vehicletypes?.map((v, index) => {
              const image = this.getImage(v.type);
              return (
                <div key={index}>
                  <h2>{v.type}</h2>
                  <img src={image} alt={v.tip.shortform} />
                  <h2>{v.type}</h2>
                  <h2>{v.displaytype}</h2>
                  <h2>{v.tip.shortform}</h2>
                </div>
              );
            })}
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Section;
