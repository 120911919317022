import Bugsnag from "@bugsnag/js";
import React, { Component } from "react";
import axios from "axios";
import SEO from "react-seo-component";
import ContentLoader from "react-content-loader";
import constants from "../../Constants";
import { Button, Col, Container, FormGroup, Input, Row } from "reactstrap";
import reviews from "../articles/reviews";
import util from "../../util";
import PlacesAutocomplete from "../../components/common/PlacesAutocomplete";
import VehiclesFader from "../../components/VehiclesFader/Vehicles";
import Marquee from "react-fast-marquee";
const baseURL = constants.baseURL;
const _ = require("lodash");

class Section extends Component {
  constructor() {
    super();

    let description = "";
    try {
      const queryParams = new URLSearchParams(window.location.search);
      description = queryParams.get("description") || "";
    } catch (error) {
      Bugsnag.notify(error);
    }

    this.state = {
      searching: false,
      description,
      homelat: 0,
      homelng: 0,
      trucktypes: [],
      vehicletypes: [],
      searchResults: [],
    };
  }
  componentDidMount() {
    if (this.state.description.length > 4) this.searchAdverts();
    this.getVehicleTypes();
  }
  getVehicleTypes() {
    const { vehicletypes } = this.state;
    if (vehicletypes?.length === 0)
      axios.get(baseURL + "/vehicle-types").then((repos) => {
        const vehicletypesdata = repos.data;
        this.setState({
          vehicletypes: vehicletypesdata?.vehicles,
          vehiclecategories: vehicletypesdata?.categories,
          vehicleconfigurations: vehicletypesdata?.configurations,
        });
      });
  }
  searchAdverts() {
    const { trucktypes, homelat, homelng, description } = this.state;
    this.setState({ searching: true, apierror: "" });
    axios
      .post(baseURL + "/search-driver-adverts", {
        description,
        trucktypes,
        homelat,
        homelng,
        source_:
          util.navigator_() + util.source_() + " qtref:" + document?.referrer,
      })
      .then((response) => {
        this.setState(
          {
            searching: false,
            searchResults: response.data,
          },
          () => {
            // window.document.getElementById("fullquote").scrollIntoView();
          }
        );
      })
      .catch((e) => {
        this.setState({
          searching: false,
          apierror: "Check your addresses or vehicle type and try again.",
        });
        Bugsnag.notify(e);
      });
  }
  getImage(trucktype) {
    try {
      return `https://media.qiktruck.co.za/${trucktype}.png`;
    } catch (error) {
      return "";
    }
  }
  getDescription(ad) {
    try {
      return typeof ad.highlight === "string" ? (
        <div
          dangerouslySetInnerHTML={{ __html: "<p>" + ad.highlight + "</p>" }}
        ></div>
      ) : (
        <p>{ad.description}</p>
      );
      // return <p>{ad.description}</p>;
    } catch (error) {
      return <p>{ad.description}</p>;
    }
  }
  getDistance(ad) {
    if (this.state.homelat === 0)
      return (
        <span className="distance notset">Your location is not set yet.</span>
      );
    try {
      return (
        <span className="distance">{Math.floor(ad.distance)} km away.</span>
      );
    } catch (error) {
      return <p>{ad.distance} km away</p>;
    }
  }
  render() {
    const { trucktypes, searching, searchResults, description } = this.state;
    return (
      <React.Fragment>
        <SEO
          title={"Transportation one stop shop."}
          titleTemplate={"QikTruck"}
          titleSeparator={`-`}
          description={
            "With over 800 well vetted transportation vehicles. QikTruck will get your goods delivered anywhere, anytime."
          }
          image={"https://media.qiktruck.co.za/qiktruck-preview-header.png"}
          pathname={"https://qiktruck.co.za/marketplace"}
          twitterUsername={"qiktrucksa"}
        />
        <section id="home">
          <Container>
            <FormGroup
              className="mb-4"
              autoComplete="new-password"
              onSubmit={() => this.searchAdverts()}
            >
              <Row className="justify-content-center">
                <Col>
                  <div className="hero-wrapper mb-4 find-transporters">
                    <h4 id="about">Find affordable transporters</h4>
                    <br />
                    <Row>
                      <Col lg={6} sm={6}>
                        <FormGroup>
                          <Input
                            id="description"
                            name="text"
                            type="text"
                            placeholder="* Search transporters, truck hire, movers, removals, door to door, furniture, ..."
                            defaultValue={description}
                            onChange={(e) => {
                              this.setState({ description: e.target.value });
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg={6} sm={6}>
                        <PlacesAutocomplete
                          type="text"
                          className="form-control"
                          id="delivery"
                          placeholder="Enter your location *"
                          required
                          autoComplete="new-password"
                          onPlaceSelected={(place) => {
                            if (!place?.geometry?.location?.lat()) {
                              Bugsnag.notify(
                                new Error(
                                  "SEARCH DELIVERY place not found: " +
                                    this.state.trucktypes
                                )
                              );
                            }
                            this.setState({
                              homelat: place?.geometry?.location?.lat(),
                              homelng: place?.geometry?.location?.lng(),
                            });
                          }}
                        />
                      </Col>
                      <Col lg={6} sm={6}>
                        <FormGroup className="mb-4" autoComplete="new-password">
                          <div
                            className={
                              "sell-truck-type " +
                              (_.isEqual(trucktypes, [
                                "motorbike",
                                "minivanopen",
                                "1tonopen",
                                "mini0.5covered",
                                "mini1.0covered",
                              ])
                                ? "selected"
                                : "")
                            }
                            onClick={() =>
                              this.setState({
                                trucktypes: [
                                  "motorbike",
                                  "minivanopen",
                                  "1tonopen",
                                  "mini0.5covered",
                                  "mini1.0covered",
                                ],
                              })
                            }
                          >
                            1 Ton or less
                          </div>
                          <div
                            className={
                              "sell-truck-type " +
                              (_.isEqual(trucktypes, [
                                "1.5tonopen",
                                "1.5tonclosed",
                              ])
                                ? "selected"
                                : "")
                            }
                            onClick={() =>
                              this.setState({
                                trucktypes: ["1.5tonopen", "1.5tonclosed"],
                              })
                            }
                          >
                            1.5 Ton
                          </div>
                          <div
                            className={
                              "sell-truck-type " +
                              (_.isEqual(trucktypes, ["4tonopen", "4tonclosed"])
                                ? "selected"
                                : "")
                            }
                            onClick={() =>
                              this.setState({
                                trucktypes: ["4tonopen", "4tonclosed"],
                              })
                            }
                          >
                            3/4 Ton
                          </div>
                          <div
                            className={
                              "sell-truck-type " +
                              (_.isEqual(trucktypes, ["8tonopen", "8tonclosed"])
                                ? "selected"
                                : "")
                            }
                            onClick={() =>
                              this.setState({
                                trucktypes: ["8tonopen", "8tonclosed"],
                              })
                            }
                          >
                            8 Ton
                          </div>
                          <div
                            className={
                              "sell-truck-type " +
                              (_.isEqual(trucktypes, [
                                "big3.0open",
                                "big3.0covered",
                              ])
                                ? "selected"
                                : "")
                            }
                            onClick={() =>
                              this.setState({
                                trucktypes: ["big3.0open", "big3.0covered"],
                              })
                            }
                          >
                            14 Ton
                          </div>
                          <div
                            className={
                              "sell-truck-type " +
                              (_.isEqual(trucktypes, ["tow1.5open"])
                                ? "selected"
                                : "")
                            }
                            onClick={() =>
                              this.setState({
                                trucktypes: ["tow1.5open"],
                              })
                            }
                          >
                            Tow trucks
                          </div>
                          <div
                            className={
                              "sell-truck-type " +
                              (_.isEqual(trucktypes, ["8tontipperandtrailer"])
                                ? "selected"
                                : "")
                            }
                            onClick={() =>
                              this.setState({
                                trucktypes: ["8tontipperandtrailer"],
                              })
                            }
                          >
                            Tippers
                          </div>
                          <div
                            className={"sell-truck-type reset"}
                            onClick={() =>
                              this.setState({
                                description: "",
                                homelat: 0,
                                homelng: 0,
                                trucktypes: [],
                              })
                            }
                          >
                            RESET
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col>
                  <Button
                    className="search-adverts"
                    color="primary"
                    disabled={searching}
                    onClick={() => {
                      this.searchAdverts();
                      // window.history.replaceState(
                      //   null,
                      //   "New Page Title",
                      //   `/marketplace/?description=${description}&homelat=${homelat}&homelng=${homelng}&trucktypes=${trucktypes}`
                      // );
                    }}
                  >
                    Search over 800 transporters
                  </Button>
                  <br /> <br />
                </Col>
              </Row>
              {searching && (
                <Row className="justify-content-center search-results">
                  <Col lg={12} sm={12} md={12}>
                    <br />
                    <br />

                    <>
                      <ContentLoader
                        viewBox="0 0 380 70"
                        speed={3}
                        backgroundColor={"#cccccc"}
                        foregroundColor={"#d9ffe3"}
                      >
                        {/* Only SVG shapes */}
                        <rect
                          x="0"
                          y="0"
                          rx="5"
                          ry="5"
                          width="45"
                          height="45"
                        />
                        <rect
                          x="80"
                          y="5"
                          rx="4"
                          ry="4"
                          width="100"
                          height="13"
                        />
                        <rect
                          x="80"
                          y="28"
                          rx="3"
                          ry="3"
                          width="280"
                          height="10"
                        />
                      </ContentLoader>
                    </>
                  </Col>
                </Row>
              )}
              {!searching && (
                <Row className="justify-content-center search-results">
                  <Col lg={12} sm={12} md={12}>
                    <br />
                    <h4>
                      Results <span>{searchResults?.length}</span>
                    </h4>
                    {searchResults?.length === 0 && (
                      <>
                        <p>
                          <i>
                            Try looking for another search term, location or
                            vehicle size...
                          </i>
                          <br />
                          <br />
                          Welcome to the QIKTRUCK Transportation Marketplace.
                          <br />
                          Your One Stop Shop for moving goods securely from
                          point A to point B,C,D,...
                          <br />
                          <br />
                          Transporters may use their well known name or services
                          such as truck hire, bakkie hire, door to door
                          deliveries.
                        </p>
                        <p>
                          1. The transportation market is extremely competitive,
                          with many different providers offering a variety of
                          services.
                          <br />
                          2. It can be difficult to find the right provider for
                          your specific needs, but it's important to shop around
                          and compare options.
                          <br />
                          3. There are many things to consider when choosing a
                          transportation provider, such as cost, quality, and
                          reliability.
                          <br />
                          4. QikTruck is a leading transportation marketplace
                          that offers a convenient, easy-to-use platform for
                          finding the right provider for your needs.
                          <br />
                          5. With QikTruck, you can quickly and easily book and
                          manage your transportation needs, and track your
                          shipment in real-time.
                          <br />
                          6. Fair and Affordable pricing for both the happy
                          customer and happy transporter.
                        </p>
                        <br />
                        <p>
                          All our transporters are vetted by QIKTRUCK. Pty Ltd.
                          and approved for the services that they are providing.
                          Worry less about security and professionalism.
                        </p>
                        <p>
                          What are transport services? <br />
                          <br />
                          Transportation services include the movement of goods
                          and services, people, and animals from one location to
                          another by rail, road, air, sea, cable, space, or
                          pipeline. Transportation services can be divided into
                          three different areas: infrastructure, vehicles, and
                          operations. QikTruck is here to glue all these areas
                          through our smart software and automated operations.
                        </p>
                        <h3 className="font-18">Customer reviews</h3>
                        <Marquee
                          gradient={true}
                          gradientWidth={0}
                          speed={6}
                          direction="right"
                        >
                          {reviews.map((a, index) => (
                            <div key={index}>
                              <div className="home-reviews">
                                <h1>{a.heading}</h1>
                                <div className="date-article-added">
                                  <i className="mdi mdi-clock-outline ml-2"></i>
                                  {a.date}
                                  <i className="mdi mdi-pin ml-2"></i>
                                  {a.location}
                                  {Array.from({ length: a.category }).map(
                                    (x, index) => (
                                      <i
                                        className="mdi mdi-star"
                                        key={index}
                                      ></i>
                                    )
                                  )}
                                </div>
                                <p>{a.copy}</p>
                              </div>
                            </div>
                          ))}
                        </Marquee>
                      </>
                    )}
                    <br />
                    {searchResults?.map((ad, index) => {
                      return (
                        <div className="ad-preview" key={index}>
                          <a
                            href={
                              "/?v=" + ad.vehicleid + "&ad=" + ad.driveradvertid
                            }
                            className="ad-link"
                          >
                            <div className="ad-image">
                              <img
                                src={`https://advertsmedia.qiktruck.co.za/${ad.vehicleid}/default`}
                                alt="-"
                              />
                            </div>
                            <div className="ad-info">
                              <h6>{ad.title}</h6>
                              {this.getDescription(ad)}
                            </div>
                            {this.getDistance(ad)}

                            <span className="getauote">Get a quote ></span>
                          </a>
                        </div>
                      );
                    })}
                  </Col>
                  <VehiclesFader />
                </Row>
              )}
            </FormGroup>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Section;
